export const ClientAppICollectionApiResponse = {
    type: {
        name: 'Composite',
        className: 'ClientAppICollectionApiResponse',
        modelProperties: {
            contentTypes: {
                serializedName: 'contentTypes',
                nullable: true,
                type: {
                    name: 'Sequence',
                    element: {
                        type: {
                            name: 'String',
                        },
                    },
                },
            },
            apiMessage: {
                serializedName: 'apiMessage',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            responseCode: {
                serializedName: 'responseCode',
                type: {
                    name: 'Number',
                },
            },
            result: {
                serializedName: 'result',
                nullable: true,
                type: {
                    name: 'Sequence',
                    element: {
                        type: {
                            name: 'Composite',
                            className: 'ClientApp',
                        },
                    },
                },
            },
            declaredType: {
                serializedName: 'declaredType',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            statusCode: {
                serializedName: 'statusCode',
                nullable: true,
                type: {
                    name: 'Number',
                },
            },
        },
    },
};
export const ClientApp = {
    type: {
        name: 'Composite',
        className: 'ClientApp',
        modelProperties: {
            clientId: {
                serializedName: 'clientId',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            displayName: {
                serializedName: 'displayName',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
        },
    },
};
export const ClientAppCreateRequest = {
    type: {
        name: 'Composite',
        className: 'ClientAppCreateRequest',
        modelProperties: {
            clientId: {
                serializedName: 'clientId',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            secret: {
                serializedName: 'secret',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
        },
    },
};
export const ClientAppApiResponse = {
    type: {
        name: 'Composite',
        className: 'ClientAppApiResponse',
        modelProperties: {
            contentTypes: {
                serializedName: 'contentTypes',
                nullable: true,
                type: {
                    name: 'Sequence',
                    element: {
                        type: {
                            name: 'String',
                        },
                    },
                },
            },
            apiMessage: {
                serializedName: 'apiMessage',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            responseCode: {
                serializedName: 'responseCode',
                type: {
                    name: 'Number',
                },
            },
            result: {
                serializedName: 'result',
                type: {
                    name: 'Composite',
                    className: 'ClientApp',
                },
            },
            declaredType: {
                serializedName: 'declaredType',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            statusCode: {
                serializedName: 'statusCode',
                nullable: true,
                type: {
                    name: 'Number',
                },
            },
        },
    },
};
export const OkResponseApiResponse = {
    type: {
        name: 'Composite',
        className: 'OkResponseApiResponse',
        modelProperties: {
            contentTypes: {
                serializedName: 'contentTypes',
                nullable: true,
                type: {
                    name: 'Sequence',
                    element: {
                        type: {
                            name: 'String',
                        },
                    },
                },
            },
            apiMessage: {
                serializedName: 'apiMessage',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            responseCode: {
                serializedName: 'responseCode',
                type: {
                    name: 'Number',
                },
            },
            result: {
                serializedName: 'result',
                type: {
                    name: 'Composite',
                    className: 'OkResponse',
                },
            },
            declaredType: {
                serializedName: 'declaredType',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            statusCode: {
                serializedName: 'statusCode',
                nullable: true,
                type: {
                    name: 'Number',
                },
            },
        },
    },
};
export const OkResponse = {
    type: {
        name: 'Composite',
        className: 'OkResponse',
        modelProperties: {
            success: {
                serializedName: 'success',
                type: {
                    name: 'Boolean',
                },
            },
        },
    },
};
export const StringListApiResponse = {
    type: {
        name: 'Composite',
        className: 'StringListApiResponse',
        modelProperties: {
            contentTypes: {
                serializedName: 'contentTypes',
                nullable: true,
                type: {
                    name: 'Sequence',
                    element: {
                        type: {
                            name: 'String',
                        },
                    },
                },
            },
            apiMessage: {
                serializedName: 'apiMessage',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            responseCode: {
                serializedName: 'responseCode',
                type: {
                    name: 'Number',
                },
            },
            result: {
                serializedName: 'result',
                nullable: true,
                type: {
                    name: 'Sequence',
                    element: {
                        type: {
                            name: 'String',
                        },
                    },
                },
            },
            declaredType: {
                serializedName: 'declaredType',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            statusCode: {
                serializedName: 'statusCode',
                nullable: true,
                type: {
                    name: 'Number',
                },
            },
        },
    },
};
export const UserCreateRequest = {
    type: {
        name: 'Composite',
        className: 'UserCreateRequest',
        modelProperties: {
            setPasswordAfterConfirmation: {
                serializedName: 'setPasswordAfterConfirmation',
                type: {
                    name: 'Boolean',
                },
            },
            password: {
                serializedName: 'password',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            isActive: {
                serializedName: 'isActive',
                type: {
                    name: 'Boolean',
                },
            },
            firstName: {
                serializedName: 'firstName',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            middleName: {
                serializedName: 'middleName',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            lastName: {
                serializedName: 'lastName',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            email: {
                serializedName: 'email',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            roles: {
                serializedName: 'roles',
                nullable: true,
                type: {
                    name: 'Sequence',
                    element: {
                        type: {
                            name: 'String',
                        },
                    },
                },
            },
        },
    },
};
export const UserDTOApiResponse = {
    type: {
        name: 'Composite',
        className: 'UserDTOApiResponse',
        modelProperties: {
            contentTypes: {
                serializedName: 'contentTypes',
                nullable: true,
                type: {
                    name: 'Sequence',
                    element: {
                        type: {
                            name: 'String',
                        },
                    },
                },
            },
            apiMessage: {
                serializedName: 'apiMessage',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            responseCode: {
                serializedName: 'responseCode',
                type: {
                    name: 'Number',
                },
            },
            result: {
                serializedName: 'result',
                type: {
                    name: 'Composite',
                    className: 'UserDTO',
                },
            },
            declaredType: {
                serializedName: 'declaredType',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            statusCode: {
                serializedName: 'statusCode',
                nullable: true,
                type: {
                    name: 'Number',
                },
            },
        },
    },
};
export const UserDTO = {
    type: {
        name: 'Composite',
        className: 'UserDTO',
        modelProperties: {
            aspNetUserId: {
                serializedName: 'aspNetUserId',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            isActive: {
                serializedName: 'isActive',
                type: {
                    name: 'Boolean',
                },
            },
            login: {
                serializedName: 'login',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            fullName: {
                serializedName: 'fullName',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            firstName: {
                serializedName: 'firstName',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            middleName: {
                serializedName: 'middleName',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            lastName: {
                serializedName: 'lastName',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            email: {
                serializedName: 'email',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            roles: {
                serializedName: 'roles',
                nullable: true,
                type: {
                    name: 'Sequence',
                    element: {
                        type: {
                            name: 'String',
                        },
                    },
                },
            },
        },
    },
};
export const StringApiResponse = {
    type: {
        name: 'Composite',
        className: 'StringApiResponse',
        modelProperties: {
            contentTypes: {
                serializedName: 'contentTypes',
                nullable: true,
                type: {
                    name: 'Sequence',
                    element: {
                        type: {
                            name: 'String',
                        },
                    },
                },
            },
            apiMessage: {
                serializedName: 'apiMessage',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            responseCode: {
                serializedName: 'responseCode',
                type: {
                    name: 'Number',
                },
            },
            result: {
                serializedName: 'result',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            declaredType: {
                serializedName: 'declaredType',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            statusCode: {
                serializedName: 'statusCode',
                nullable: true,
                type: {
                    name: 'Number',
                },
            },
        },
    },
};
export const UserPasswordResetRequest = {
    type: {
        name: 'Composite',
        className: 'UserPasswordResetRequest',
        modelProperties: {
            login: {
                serializedName: 'login',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            token: {
                serializedName: 'token',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            newPassword: {
                serializedName: 'newPassword',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
        },
    },
};
export const UserConfirmRequest = {
    type: {
        name: 'Composite',
        className: 'UserConfirmRequest',
        modelProperties: {
            email: {
                serializedName: 'email',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            token: {
                serializedName: 'token',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            password: {
                serializedName: 'password',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
        },
    },
};
export const UserDTOPaginatedResultApiResponse = {
    type: {
        name: 'Composite',
        className: 'UserDTOPaginatedResultApiResponse',
        modelProperties: {
            contentTypes: {
                serializedName: 'contentTypes',
                nullable: true,
                type: {
                    name: 'Sequence',
                    element: {
                        type: {
                            name: 'String',
                        },
                    },
                },
            },
            apiMessage: {
                serializedName: 'apiMessage',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            responseCode: {
                serializedName: 'responseCode',
                type: {
                    name: 'Number',
                },
            },
            result: {
                serializedName: 'result',
                type: {
                    name: 'Composite',
                    className: 'UserDTOPaginatedResult',
                },
            },
            declaredType: {
                serializedName: 'declaredType',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            statusCode: {
                serializedName: 'statusCode',
                nullable: true,
                type: {
                    name: 'Number',
                },
            },
        },
    },
};
export const UserDTOPaginatedResult = {
    type: {
        name: 'Composite',
        className: 'UserDTOPaginatedResult',
        modelProperties: {
            skipped: {
                serializedName: 'skipped',
                type: {
                    name: 'Number',
                },
            },
            taked: {
                serializedName: 'taked',
                type: {
                    name: 'Number',
                },
            },
            totalCount: {
                serializedName: 'totalCount',
                type: {
                    name: 'Number',
                },
            },
            result: {
                serializedName: 'result',
                nullable: true,
                type: {
                    name: 'Sequence',
                    element: {
                        type: {
                            name: 'Composite',
                            className: 'UserDTO',
                        },
                    },
                },
            },
        },
    },
};
export const UserUpdateRequest = {
    type: {
        name: 'Composite',
        className: 'UserUpdateRequest',
        modelProperties: {
            firstName: {
                serializedName: 'firstName',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            middleName: {
                serializedName: 'middleName',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            lastName: {
                serializedName: 'lastName',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            login: {
                serializedName: 'login',
                nullable: true,
                type: {
                    name: 'String',
                },
            },
            roles: {
                serializedName: 'roles',
                nullable: true,
                type: {
                    name: 'Sequence',
                    element: {
                        type: {
                            name: 'String',
                        },
                    },
                },
            },
        },
    },
};
