import { notification } from "antd";
export function sendErrorMessage(status, message) {
    switch (status) {
        case 500:
            notification.open({
                type: 'error',
                message: "Серверная ошибка",
                description: 'Сервер не смог обработать запрос. Попробуйте позже или обратиитесь в службу технической поддрежки',
            });
            break;
        case 400:
            notification.open({
                type: 'error',
                message: "Запрос отклонен",
                description: message
            });
            break;
        default:
            notification.open({
                type: 'error',
                message: "Неопознанная ошибка",
                description: 'Что-то пошло не так. Проверьте связь с сервером',
            });
    }
}
export function sendSuccessMessage(status, method, message) {
    switch (status) {
        case 201:
        case 200: {
            switch (method) {
                case "POST": {
                    notification.open({
                        type: 'success',
                        message: message,
                    });
                    break;
                }
                case "PUT": {
                    notification.open({
                        type: 'success',
                        message: message,
                    });
                    break;
                }
                case "DELETE": {
                    notification.open({
                        type: 'success',
                        message: message,
                    });
                    break;
                }
            }
            break;
        }
    }
}
