import * as coreClient from '@azure/core-client';
import * as Parameters from './models/parameters';
import * as Mappers from './models/mappers';
export class UserClient extends coreClient.ServiceClient {
    /**
     * Initializes a new instance of the UserClient class.
     * @param credentials Subscription credentials which uniquely identify client subscription.
     * @param $host server parameter
     * @param options The parameter options
     */
    constructor(credentials, $host, options) {
        var _a, _b;
        if (credentials === undefined) {
            throw new Error("'credentials' cannot be null");
        }
        if ($host === undefined) {
            throw new Error("'$host' cannot be null");
        }
        // Initializing default values for options
        if (!options) {
            options = {};
        }
        const defaults = {
            requestContentType: 'application/json; charset=utf-8',
            credential: credentials,
        };
        const packageDetails = `azsdk-js-userClient/1.0.0-beta.1`;
        const userAgentPrefix = options.userAgentOptions && options.userAgentOptions.userAgentPrefix
            ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
            : `${packageDetails}`;
        const optionsWithDefaults = Object.assign(Object.assign(Object.assign({}, defaults), options), { userAgentOptions: {
                userAgentPrefix,
            }, baseUri: (_b = (_a = options.endpoint) !== null && _a !== void 0 ? _a : options.baseUri) !== null && _b !== void 0 ? _b : '{$host}' });
        super(optionsWithDefaults);
        // Parameter assignments
        this.$host = $host;
    }
    /** @param options The options parameters. */
    exchange(options) {
        return this.sendOperationRequest({ options }, exchangeOperationSpec);
    }
    /** @param options The options parameters. */
    getClients(options) {
        return this.sendOperationRequest({ options }, getClientsOperationSpec);
    }
    /** @param options The options parameters. */
    createClient(options) {
        return this.sendOperationRequest({ options }, createClientOperationSpec);
    }
    /**
     * @param clientId
     * @param options The options parameters.
     */
    deleteClient(clientId, options) {
        return this.sendOperationRequest({ clientId, options }, deleteClientOperationSpec);
    }
    /** @param options The options parameters. */
    getRoles(options) {
        return this.sendOperationRequest({ options }, getRolesOperationSpec);
    }
    /** @param options The options parameters. */
    createRole(options) {
        return this.sendOperationRequest({ options }, createRoleOperationSpec);
    }
    /** @param options The options parameters. */
    deleteRole(options) {
        return this.sendOperationRequest({ options }, deleteRoleOperationSpec);
    }
    /** @param options The options parameters. */
    createUser(options) {
        return this.sendOperationRequest({ options }, createUserOperationSpec);
    }
    /** @param options The options parameters. */
    requestResetPassword(options) {
        return this.sendOperationRequest({ options }, requestResetPasswordOperationSpec);
    }
    /** @param options The options parameters. */
    resetPassword(options) {
        return this.sendOperationRequest({ options }, resetPasswordOperationSpec);
    }
    /**
     * @param email
     * @param token
     * @param options The options parameters.
     */
    verifyUserToken(email, token, options) {
        return this.sendOperationRequest({ email, token, options }, verifyUserTokenOperationSpec);
    }
    /** @param options The options parameters. */
    setPasswordByUser(options) {
        return this.sendOperationRequest({ options }, setPasswordByUserOperationSpec);
    }
    /**
     * @param login
     * @param block
     * @param options The options parameters.
     */
    enableUser(login, block, options) {
        return this.sendOperationRequest({ login, block, options }, enableUserOperationSpec);
    }
    /** @param options The options parameters. */
    confirmUser(options) {
        return this.sendOperationRequest({ options }, confirmUserOperationSpec);
    }
    /** @param options The options parameters. */
    getBlockedUsers(options) {
        return this.sendOperationRequest({ options }, getBlockedUsersOperationSpec);
    }
    /** @param options The options parameters. */
    getUsers(options) {
        return this.sendOperationRequest({ options }, getUsersOperationSpec);
    }
    /** @param options The options parameters. */
    updateUser(options) {
        return this.sendOperationRequest({ options }, updateUserOperationSpec);
    }
    /**
     * @param login
     * @param options The options parameters.
     */
    getUserByLogin(login, options) {
        return this.sendOperationRequest({ login, options }, getUserByLoginOperationSpec);
    }
    /**
     * @param userId
     * @param options The options parameters.
     */
    getUserById(userId, options) {
        return this.sendOperationRequest({ userId, options }, getUserByIdOperationSpec);
    }
    /** @param options The options parameters. */
    getUsersWithRoles(options) {
        return this.sendOperationRequest({ options }, getUsersWithRolesOperationSpec);
    }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);
const exchangeOperationSpec = {
    path: '/connect/token',
    httpMethod: 'POST',
    responses: { 200: {} },
    urlParameters: [Parameters.$host],
    serializer,
};
const getClientsOperationSpec = {
    path: '/api/Clients',
    httpMethod: 'GET',
    responses: {
        200: {
            bodyMapper: Mappers.ClientAppICollectionApiResponse,
        },
        201: {
            bodyMapper: Mappers.ClientAppICollectionApiResponse,
        },
        400: {
            bodyMapper: Mappers.ClientAppICollectionApiResponse,
        },
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer,
};
const createClientOperationSpec = {
    path: '/api/Clients',
    httpMethod: 'POST',
    responses: {
        200: {
            bodyMapper: Mappers.ClientAppApiResponse,
        },
        201: {
            bodyMapper: Mappers.ClientAppApiResponse,
        },
        400: {
            bodyMapper: Mappers.ClientAppApiResponse,
        },
    },
    requestBody: Parameters.body,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType],
    mediaType: 'json',
    serializer,
};
const deleteClientOperationSpec = {
    path: '/api/Clients/{clientId}',
    httpMethod: 'DELETE',
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse,
        },
        201: {
            bodyMapper: Mappers.OkResponseApiResponse,
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse,
        },
    },
    urlParameters: [Parameters.$host, Parameters.clientId],
    headerParameters: [Parameters.accept],
    serializer,
};
const getRolesOperationSpec = {
    path: '/api/Roles',
    httpMethod: 'GET',
    responses: {
        200: {
            bodyMapper: Mappers.StringListApiResponse,
        },
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer,
};
const createRoleOperationSpec = {
    path: '/api/Roles',
    httpMethod: 'POST',
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse,
        },
        201: {
            bodyMapper: Mappers.OkResponseApiResponse,
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse,
        },
    },
    queryParameters: [Parameters.roleName],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer,
};
const deleteRoleOperationSpec = {
    path: '/api/Roles',
    httpMethod: 'DELETE',
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse,
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse,
        },
    },
    queryParameters: [Parameters.roleName],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer,
};
const createUserOperationSpec = {
    path: '/api/Users/Create',
    httpMethod: 'POST',
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOApiResponse,
        },
        201: {
            bodyMapper: Mappers.UserDTOApiResponse,
        },
        400: {
            bodyMapper: Mappers.UserDTOApiResponse,
        },
    },
    requestBody: Parameters.body1,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType],
    mediaType: 'json',
    serializer,
};
const requestResetPasswordOperationSpec = {
    path: '/api/Users/RequestResetPassword',
    httpMethod: 'POST',
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse,
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse,
        },
    },
    queryParameters: [Parameters.email],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer,
};
const resetPasswordOperationSpec = {
    path: '/api/Users/ResetPassword',
    httpMethod: 'POST',
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse,
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse,
        },
    },
    queryParameters: [Parameters.login, Parameters.newPassword],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer,
};
const verifyUserTokenOperationSpec = {
    path: '/api/Users/Verify/{email}/{token}',
    httpMethod: 'POST',
    responses: {
        200: {
            bodyMapper: Mappers.StringApiResponse,
        },
        400: {
            bodyMapper: Mappers.StringApiResponse,
        },
    },
    urlParameters: [Parameters.$host, Parameters.email1, Parameters.token],
    headerParameters: [Parameters.accept],
    serializer,
};
const setPasswordByUserOperationSpec = {
    path: '/api/Users/set-password-by-user',
    httpMethod: 'POST',
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse,
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse,
        },
    },
    requestBody: Parameters.body2,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType],
    mediaType: 'json',
    serializer,
};
const enableUserOperationSpec = {
    path: '/api/Users/EnableUser/{login}/{block}',
    httpMethod: 'POST',
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse,
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse,
        },
    },
    urlParameters: [Parameters.$host, Parameters.login1, Parameters.block],
    headerParameters: [Parameters.accept],
    serializer,
};
const confirmUserOperationSpec = {
    path: '/api/Users/Confirm',
    httpMethod: 'POST',
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOApiResponse,
        },
        400: {
            bodyMapper: Mappers.UserDTOApiResponse,
        },
    },
    requestBody: Parameters.body3,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType],
    mediaType: 'json',
    serializer,
};
const getBlockedUsersOperationSpec = {
    path: '/api/Users/Blocked',
    httpMethod: 'GET',
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOPaginatedResultApiResponse,
        },
        400: {
            bodyMapper: Mappers.UserDTOPaginatedResultApiResponse,
        },
    },
    queryParameters: [Parameters.take, Parameters.skip, Parameters.searchString, Parameters.sortOrder, Parameters.roles],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer,
};
const getUsersOperationSpec = {
    path: '/api/Users',
    httpMethod: 'GET',
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOPaginatedResultApiResponse,
        },
        400: {
            bodyMapper: Mappers.UserDTOPaginatedResultApiResponse,
        },
    },
    queryParameters: [Parameters.take, Parameters.skip, Parameters.searchString, Parameters.sortOrder, Parameters.roles],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer,
};
const updateUserOperationSpec = {
    path: '/api/Users',
    httpMethod: 'PUT',
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOApiResponse,
        },
        400: {
            bodyMapper: Mappers.UserDTOApiResponse,
        },
        403: {
            bodyMapper: Mappers.UserDTOApiResponse,
        },
    },
    requestBody: Parameters.body4,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType],
    mediaType: 'json',
    serializer,
};
const getUserByLoginOperationSpec = {
    path: '/api/Users/ByLogin/{login}',
    httpMethod: 'GET',
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOApiResponse,
        },
        400: {
            bodyMapper: Mappers.UserDTOApiResponse,
        },
    },
    urlParameters: [Parameters.$host, Parameters.login1],
    headerParameters: [Parameters.accept],
    serializer,
};
const getUserByIdOperationSpec = {
    path: '/api/Users/ById/{userId}',
    httpMethod: 'GET',
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOApiResponse,
        },
        400: {
            bodyMapper: Mappers.UserDTOApiResponse,
        },
    },
    urlParameters: [Parameters.$host, Parameters.userId],
    headerParameters: [Parameters.accept],
    serializer,
};
const getUsersWithRolesOperationSpec = {
    path: '/api/Users/with-roles',
    httpMethod: 'GET',
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOPaginatedResultApiResponse,
        },
        400: {
            bodyMapper: Mappers.UserDTOPaginatedResultApiResponse,
        },
    },
    queryParameters: [Parameters.take, Parameters.skip, Parameters.searchString, Parameters.sortOrder, Parameters.roles],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer,
};
