import { jsx as _jsx } from "react/jsx-runtime";
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";
import configureStore, { history } from './appRedux/store';
import App from "./containers/App";
import ruMessages from "devextreme/localization/messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";
import config from 'devextreme/core/config';
import mttSettings from "./services/config/MttSettings";
import { MttAuthClient } from "./services/auth/MttAuthClient";
import { mttAuthService } from "./services/auth/MttAuthService";
import { AuthTokenCredential } from "./services/auth/Providers/AuthTokenCredential";
import { accessTokenProvider } from "./services/auth/Providers/AccessTokenProvider";
import { refreshTokenProvider } from "./services/auth/Providers/RefreshTokenProvider";
import mttClient from "services/appApi/MttClient";
import mttUsersClient from "./services/usersApi/MttUsersClient";
import { ApiClient } from "./restProviders/apiClient/apiClient";
import { UserClient } from "./restProviders/authClient";
const store = configureStore();
console.log("================<STARTING APP>=============");
mttSettings.getConfig();
const mttAuthClient = new MttAuthClient("", mttSettings.AuthUrl.trim().concat('', '/connect/token'));
const authTokenCredential = new AuthTokenCredential(accessTokenProvider);
accessTokenProvider.init("access-token");
refreshTokenProvider.init("refresh-token");
mttAuthService.init(mttAuthClient, accessTokenProvider, refreshTokenProvider);
const clientOptions = {
    allowInsecureConnection: true,
    retryOptions: { maxRetries: 0 }
};
const apiCLient = new ApiClient(authTokenCredential, mttSettings.MttApiUrl, clientOptions);
const usersClient = new UserClient(authTokenCredential, mttSettings.AuthUrl, clientOptions);
mttClient.init(apiCLient, accessTokenProvider).addResponseHandlerInterceptor();
mttUsersClient.init(usersClient, accessTokenProvider).addResponseHandlerInterceptor();
function NextApp() {
    loadMessages(ruMessages);
    locale("ru-RU");
    config({ defaultCurrency: 'RUB' });
    /*
    useEffect(() => {
        const initAuthObserver = async () => {
            await mttAuthService.initAccessTokenObserver(() => alert('no_auth'))
        };
        initAuthObserver();
    }, []);*/
    //TODO: сделать редирект на страницу логина
    mttAuthService.initAccessTokenObserver(() => { });
    return (_jsx(Provider, Object.assign({ store: store }, { children: _jsx(ConnectedRouter, Object.assign({ history: history }, { children: _jsx(Switch, { children: _jsx(Route, { path: "/", component: App }) }) })) })));
}
export default NextApp;
