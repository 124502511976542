var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isStatusUnauthorized } from "./http-helper";
export function responseHandlerInterceptor(httpClient, isSuccessHandler, isErrorHandler) {
    httpClient.pipeline.addPolicy({
        name: "ErrorHandlerPolicy",
        sendRequest: (request, next) => __awaiter(this, void 0, void 0, function* () {
            return next(request)
                .catch((pipelineError) => {
                if (isStatusUnauthorized(pipelineError.statusCode)) {
                    localStorage.clear();
                    window.location.pathname = '/user-auth/sign-in';
                }
                /*  if (isStatusError(pipelineError.statusCode)) {
                    isErrorHandler(
                        pipelineError.statusCode,
                        pipelineError.message
                    );
                }*/
                throw pipelineError;
            })
                .then((pipelineResponse) => {
                /*if (isStatusSuccess(pipelineResponse.status)) {
                    const response = JSON.parse(pipelineResponse.bodyAsText)
                    if (response.apiMessage) {

                        isSuccessHandler(
                            pipelineResponse.status,
                            request.method,
                            response.apiMessage
                        );
                    }
                    return pipelineResponse;
                }
                if (isStatusBadRequest(pipelineResponse.status)){
                    const error = JSON.parse(pipelineResponse.bodyAsText);
                    console.log(error)
                    isErrorHandler(
                        pipelineResponse.status,
                        error.apiMessage
                    );
                    return pipelineResponse;
                }

                if (isStatusError(pipelineResponse.status)) {
                    const error = JSON.parse(pipelineResponse.bodyAsText);
                    isErrorHandler(
                        pipelineResponse.status,
                        error.apiMessage
                    );
                }*/
                return pipelineResponse;
            });
        })
    });
}
