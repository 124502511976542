export function isStatusSuccess(statusCode) {
    return statusCode >= 200 && statusCode <= 399;
}
export function isStatusError(statusCode) {
    return statusCode < 200 || statusCode > 399;
}
export function isServerError(statusCode) {
    return statusCode > 500;
}
export function isStatusBadRequest(statusCode) {
    return statusCode === 400 || (statusCode > 401 && statusCode < 500);
}
export function isStatusUnauthorized(statusCode) {
    return statusCode === 401;
}
export function isStatusAccessDenied(statusCode) {
    return statusCode === 403;
}
