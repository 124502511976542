class MttSettings {
    constructor() {
        this.MttApiUrl = '';
        this.AuthUrl = '';
        this.StrapiUrl = '';
    }
    getConfig() {
        this.MttApiUrl = process.env.REACT_APP_API_URL;
        this.AuthUrl = process.env.REACT_APP_AUTH_URL;
        this.StrapiUrl = process.env.REACT_APP_STRAPI_URL;
    }
}
export default new MttSettings();
