export class RefreshTokenProvider {
    init(storageKey) {
        if (storageKey)
            this.storageKey = storageKey;
        else
            this.storageKey = "refresh-token";
        return this;
    }
    getRefreshToken() {
        return localStorage.getItem(this.storageKey) || "";
    }
    hasRefreshToken() {
        return Boolean(this.getRefreshToken());
    }
    setRefreshToken(refreshToken) {
        localStorage.setItem(this.storageKey, refreshToken);
    }
    destroy() {
        localStorage.removeItem(this.storageKey);
    }
}
export const refreshTokenProvider = new RefreshTokenProvider();
