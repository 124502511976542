export class AccessTokenProvider {
    init(storageKey) {
        if (storageKey)
            this.storageKey = storageKey;
        else
            this.storageKey = "access-token";
        return this;
    }
    getAccessToken() {
        return localStorage.getItem(this.storageKey) || "";
    }
    hasAccessToken() {
        return Boolean(this.getAccessToken());
    }
    setAccessToken(refreshToken) {
        localStorage.setItem(this.storageKey, refreshToken);
    }
    destroy() {
        localStorage.removeItem(this.storageKey);
    }
}
export const accessTokenProvider = new AccessTokenProvider();
