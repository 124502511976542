export const BlockReasonDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "BlockReasonDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "BlockReasonDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const BlockReasonDto = {
    type: {
        name: "Composite",
        className: "BlockReasonDto",
        modelProperties: {
            memberAccessBlockReasonId: {
                serializedName: "memberAccessBlockReasonId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            name: {
                constraints: {
                    MinLength: 1
                },
                serializedName: "name",
                required: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const BlockReasonDtoApiResponse = {
    type: {
        name: "Composite",
        className: "BlockReasonDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "BlockReasonDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const BudgetDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "BudgetDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "BudgetDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const BudgetDto = {
    type: {
        name: "Composite",
        className: "BudgetDto",
        modelProperties: {
            budgetId: {
                serializedName: "budgetId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            programId: {
                serializedName: "programId",
                type: {
                    name: "Number"
                }
            },
            amount: {
                serializedName: "amount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            beginDate: {
                serializedName: "beginDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            endDate: {
                serializedName: "endDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            contractNumber: {
                serializedName: "contractNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            contractDate: {
                serializedName: "contractDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            providersAllCount: {
                serializedName: "providersAllCount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            providersConfirmedCount: {
                serializedName: "providersConfirmedCount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            providersAwaitingCount: {
                serializedName: "providersAwaitingCount",
                readOnly: true,
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            programName: {
                serializedName: "programName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberCount: {
                serializedName: "memberCount",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const BudgetDtoApiResponse = {
    type: {
        name: "Composite",
        className: "BudgetDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "BudgetDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const BudgetCreateRequest = {
    type: {
        name: "Composite",
        className: "BudgetCreateRequest",
        modelProperties: {
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            amount: {
                serializedName: "amount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            beginDate: {
                serializedName: "beginDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            endDate: {
                serializedName: "endDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            contractNumber: {
                serializedName: "contractNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            contractDate: {
                serializedName: "contractDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const BudgetUpdateRequest = {
    type: {
        name: "Composite",
        className: "BudgetUpdateRequest",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            amount: {
                serializedName: "amount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            beginDate: {
                serializedName: "beginDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            endDate: {
                serializedName: "endDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            contractNumber: {
                serializedName: "contractNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            contractDate: {
                serializedName: "contractDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const BudgetClaimDtoApiResponse = {
    type: {
        name: "Composite",
        className: "BudgetClaimDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "BudgetClaimDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const BudgetClaimDto = {
    type: {
        name: "Composite",
        className: "BudgetClaimDto",
        modelProperties: {
            budgetId: {
                serializedName: "budgetId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const CandidateMemberListFileICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "CandidateMemberListFileICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CandidateMemberListFile"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CandidateMemberListFile = {
    type: {
        name: "Composite",
        className: "CandidateMemberListFile",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            candidateMemberListId: {
                serializedName: "candidateMemberListId",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fileId: {
                serializedName: "fileId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            documentTypeId: {
                serializedName: "documentTypeId",
                type: {
                    name: "String"
                }
            },
            fileSizeInBytes: {
                serializedName: "fileSizeInBytes",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CandidateMemberItemListICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "CandidateMemberItemListICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CandidateMemberItemList"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CandidateMemberItemList = {
    type: {
        name: "Composite",
        className: "CandidateMemberItemList",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            projectId: {
                serializedName: "projectId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            budgetName: {
                serializedName: "budgetName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            budgetId: {
                serializedName: "budgetId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            programName: {
                serializedName: "programName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            projectName: {
                serializedName: "projectName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isSpecialProject: {
                serializedName: "isSpecialProject",
                type: {
                    name: "Boolean"
                }
            },
            isAutoload: {
                serializedName: "isAutoload",
                type: {
                    name: "Boolean"
                }
            },
            claimsCount: {
                serializedName: "claimsCount",
                type: {
                    name: "Number"
                }
            },
            memberAccessCount: {
                serializedName: "memberAccessCount",
                type: {
                    name: "Number"
                }
            },
            membersCount: {
                serializedName: "membersCount",
                type: {
                    name: "Number"
                }
            },
            candidateCount: {
                serializedName: "candidateCount",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CandidateMemberListICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "CandidateMemberListICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CandidateMemberList"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CandidateMemberList = {
    type: {
        name: "Composite",
        className: "CandidateMemberList",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            projectId: {
                serializedName: "projectId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            candidateMembers: {
                serializedName: "candidateMembers",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CandidateMember"
                        }
                    }
                }
            },
            budgetName: {
                serializedName: "budgetName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            budgetId: {
                serializedName: "budgetId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            programName: {
                serializedName: "programName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            projectName: {
                serializedName: "projectName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isSpecialProject: {
                serializedName: "isSpecialProject",
                type: {
                    name: "Boolean"
                }
            },
            isAutoload: {
                serializedName: "isAutoload",
                type: {
                    name: "Boolean"
                }
            },
            duplicates: {
                serializedName: "duplicates",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CandidateMember"
                        }
                    }
                }
            }
        }
    }
};
export const CandidateMember = {
    type: {
        name: "Composite",
        className: "CandidateMember",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            userId: {
                serializedName: "userId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            birthDate: {
                serializedName: "birthDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            phoneNumber: {
                serializedName: "phoneNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            groupTourType: {
                serializedName: "groupTourType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            season: {
                serializedName: "season",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            status: {
                serializedName: "status",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberStatus: {
                serializedName: "memberStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            candidateMemberListId: {
                serializedName: "candidateMemberListId",
                type: {
                    name: "Number"
                }
            },
            tourCategory: {
                serializedName: "tourCategory",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            tourCategoryAmount: {
                serializedName: "tourCategoryAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            region: {
                serializedName: "region",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isMember: {
                serializedName: "isMember",
                type: {
                    name: "Boolean"
                }
            },
            tourId: {
                serializedName: "tourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            groupId: {
                serializedName: "groupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            idSeries: {
                serializedName: "idSeries",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            idNumber: {
                serializedName: "idNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            sex: {
                serializedName: "sex",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            departCityId: {
                serializedName: "departCityId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            memberId: {
                serializedName: "memberId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            member: {
                serializedName: "member",
                type: {
                    name: "Composite",
                    className: "Member"
                }
            },
            competition: {
                serializedName: "competition",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const Member = {
    type: {
        name: "Composite",
        className: "Member",
        modelProperties: {
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            activateDateTime: {
                serializedName: "activateDateTime",
                type: {
                    name: "DateTime"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isBlocked: {
                serializedName: "isBlocked",
                type: {
                    name: "Boolean"
                }
            },
            claims: {
                serializedName: "claims",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MemberAccessClaim"
                        }
                    }
                }
            },
            memberAccesses: {
                serializedName: "memberAccesses",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MemberAccess"
                        }
                    }
                }
            },
            memberFullName: {
                serializedName: "memberFullName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            contactPhone: {
                serializedName: "contactPhone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            birthDate: {
                serializedName: "birthDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            candidateMembers: {
                serializedName: "candidateMembers",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "Candidate"
                        }
                    }
                }
            },
            candidateInListCount: {
                serializedName: "candidateInListCount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            memberAccessActiveCount: {
                serializedName: "memberAccessActiveCount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            claimCount: {
                serializedName: "claimCount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            changeRequests: {
                serializedName: "changeRequests",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChangeMemberDataResponseDto"
                        }
                    }
                }
            },
            competition: {
                serializedName: "competition",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isInvited: {
                serializedName: "isInvited",
                type: {
                    name: "Boolean"
                }
            },
            sletatUserId: {
                serializedName: "sletatUserId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            personalAccountRegistered: {
                serializedName: "personalAccountRegistered",
                type: {
                    name: "Boolean"
                }
            },
            personalAccountRegistrationDate: {
                serializedName: "personalAccountRegistrationDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const MemberAccessClaim = {
    type: {
        name: "Composite",
        className: "MemberAccessClaim",
        modelProperties: {
            bookingCenterClaimId: {
                serializedName: "bookingCenterClaimId",
                type: {
                    name: "Uuid"
                }
            },
            dateBegin: {
                serializedName: "dateBegin",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            dateEnd: {
                serializedName: "dateEnd",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            townFrom: {
                serializedName: "townFrom",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            status: {
                serializedName: "status",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            price: {
                serializedName: "price",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            tourGroupId: {
                serializedName: "tourGroupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourId: {
                serializedName: "tourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            currentProvider: {
                serializedName: "currentProvider",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            budgetName: {
                serializedName: "budgetName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            externalSystemId: {
                serializedName: "externalSystemId",
                type: {
                    name: "Number"
                }
            },
            townTo: {
                serializedName: "townTo",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            certificateInitialAmount: {
                serializedName: "certificateInitialAmount",
                type: {
                    name: "Number"
                }
            },
            certificateCode: {
                serializedName: "certificateCode",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const MemberAccess = {
    type: {
        name: "Composite",
        className: "MemberAccess",
        modelProperties: {
            memberAccessId: {
                serializedName: "memberAccessId",
                type: {
                    name: "Number"
                }
            },
            budget: {
                serializedName: "budget",
                type: {
                    name: "Composite",
                    className: "MemberBudget"
                }
            },
            accessExpireDate: {
                serializedName: "accessExpireDate",
                type: {
                    name: "DateTime"
                }
            },
            currentCertificate: {
                serializedName: "currentCertificate",
                type: {
                    name: "Composite",
                    className: "CurrentCertificate"
                }
            },
            currentProvider: {
                serializedName: "currentProvider",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            certificateConfirmed: {
                serializedName: "certificateConfirmed",
                type: {
                    name: "Boolean"
                }
            },
            currentClaimNumber: {
                serializedName: "currentClaimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            certificateSpentAmount: {
                serializedName: "certificateSpentAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            accessIsBlocked: {
                serializedName: "accessIsBlocked",
                type: {
                    name: "Boolean"
                }
            },
            blockReason: {
                serializedName: "blockReason",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claim: {
                serializedName: "claim",
                type: {
                    name: "Composite",
                    className: "MemberAccessClaim"
                }
            },
            candidateMemberListName: {
                serializedName: "candidateMemberListName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            candidateMemberListId: {
                serializedName: "candidateMemberListId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            competition: {
                serializedName: "competition",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            certificateInitialAmount: {
                serializedName: "certificateInitialAmount",
                type: {
                    name: "Number"
                }
            },
            logs: {
                serializedName: "logs",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MemberAccessLogDto"
                        }
                    }
                }
            }
        }
    }
};
export const MemberBudget = {
    type: {
        name: "Composite",
        className: "MemberBudget",
        modelProperties: {
            budgetId: {
                serializedName: "budgetId",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            amount: {
                serializedName: "amount",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CurrentCertificate = {
    type: {
        name: "Composite",
        className: "CurrentCertificate",
        modelProperties: {
            certificateId: {
                serializedName: "certificateId",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            initialAmount: {
                serializedName: "initialAmount",
                type: {
                    name: "Number"
                }
            },
            currentAmount: {
                serializedName: "currentAmount",
                type: {
                    name: "Number"
                }
            },
            budgetGroupNumber: {
                serializedName: "budgetGroupNumber",
                type: {
                    name: "Number"
                }
            },
            certificateIsBlocked: {
                serializedName: "certificateIsBlocked",
                nullable: true,
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const MemberAccessLogDto = {
    type: {
        name: "Composite",
        className: "MemberAccessLogDto",
        modelProperties: {
            memberAccessLogId: {
                serializedName: "memberAccessLogId",
                type: {
                    name: "Number"
                }
            },
            memberAccessId: {
                serializedName: "memberAccessId",
                type: {
                    name: "Number"
                }
            },
            isBlocked: {
                serializedName: "isBlocked",
                type: {
                    name: "Boolean"
                }
            },
            isSelfExtend: {
                serializedName: "isSelfExtend",
                type: {
                    name: "Boolean"
                }
            },
            updateDateTime: {
                serializedName: "updateDateTime",
                type: {
                    name: "DateTime"
                }
            },
            oldExpireDate: {
                serializedName: "oldExpireDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            newExpireDate: {
                serializedName: "newExpireDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            updatedByUserId: {
                serializedName: "updatedByUserId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            userName: {
                serializedName: "userName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            reason: {
                serializedName: "reason",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberAccessLogTypeId: {
                serializedName: "memberAccessLogTypeId",
                type: {
                    name: "Number"
                }
            },
            memberAccessLogType: {
                serializedName: "memberAccessLogType",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const Candidate = {
    type: {
        name: "Composite",
        className: "Candidate",
        modelProperties: {
            candidateMemberId: {
                serializedName: "candidateMemberId",
                type: {
                    name: "Number"
                }
            },
            orderNumberInCandidateList: {
                serializedName: "orderNumberInCandidateList",
                type: {
                    name: "Number"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            candidateFullName: {
                serializedName: "candidateFullName",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            candidateList: {
                serializedName: "candidateList",
                type: {
                    name: "Composite",
                    className: "CandidateList"
                }
            },
            budgetName: {
                serializedName: "budgetName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            programName: {
                serializedName: "programName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            candidateMemberStatus: {
                serializedName: "candidateMemberStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            competition: {
                serializedName: "competition",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const CandidateList = {
    type: {
        name: "Composite",
        className: "CandidateList",
        modelProperties: {
            candidateMemberListId: {
                serializedName: "candidateMemberListId",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            isSpecial: {
                serializedName: "isSpecial",
                type: {
                    name: "Boolean"
                }
            },
            isAutoLoad: {
                serializedName: "isAutoLoad",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const ChangeMemberDataResponseDto = {
    type: {
        name: "Composite",
        className: "ChangeMemberDataResponseDto",
        modelProperties: {
            memberProfileChangeRequestId: {
                serializedName: "memberProfileChangeRequestId",
                type: {
                    name: "Uuid"
                }
            },
            requestDate: {
                serializedName: "requestDate",
                type: {
                    name: "DateTime"
                }
            },
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            contactPhone: {
                serializedName: "contactPhone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            birthDate: {
                serializedName: "birthDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            approved: {
                serializedName: "approved",
                nullable: true,
                type: {
                    name: "Boolean"
                }
            },
            rejected: {
                serializedName: "rejected",
                nullable: true,
                type: {
                    name: "Boolean"
                }
            },
            duplicated: {
                serializedName: "duplicated",
                type: {
                    name: "Boolean"
                }
            },
            processedDate: {
                serializedName: "processedDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            files: {
                serializedName: "files",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FileDto"
                        }
                    }
                }
            }
        }
    }
};
export const FileDto = {
    type: {
        name: "Composite",
        className: "FileDto",
        modelProperties: {
            fileId: {
                serializedName: "fileId",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            path: {
                serializedName: "path",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const CandidateMemberDetailsListApiResponse = {
    type: {
        name: "Composite",
        className: "CandidateMemberDetailsListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "CandidateMemberDetailsList"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CandidateMemberDetailsList = {
    type: {
        name: "Composite",
        className: "CandidateMemberDetailsList",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            projectId: {
                serializedName: "projectId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            candidateMembers: {
                serializedName: "candidateMembers",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CandidateMemberDetails"
                        }
                    }
                }
            },
            budgetName: {
                serializedName: "budgetName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            budgetId: {
                serializedName: "budgetId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            programName: {
                serializedName: "programName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            projectName: {
                serializedName: "projectName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isSpecialProject: {
                serializedName: "isSpecialProject",
                type: {
                    name: "Boolean"
                }
            },
            isAutoload: {
                serializedName: "isAutoload",
                type: {
                    name: "Boolean"
                }
            },
            duplicates: {
                serializedName: "duplicates",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CandidateMember"
                        }
                    }
                }
            },
            createdByLastName: {
                serializedName: "createdByLastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createdByFirstName: {
                serializedName: "createdByFirstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createdByEmail: {
                serializedName: "createdByEmail",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const CandidateMemberDetails = {
    type: {
        name: "Composite",
        className: "CandidateMemberDetails",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            userId: {
                serializedName: "userId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            birthDate: {
                serializedName: "birthDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            phoneNumber: {
                serializedName: "phoneNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            groupTourType: {
                serializedName: "groupTourType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            season: {
                serializedName: "season",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            status: {
                serializedName: "status",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberStatus: {
                serializedName: "memberStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            candidateMemberListId: {
                serializedName: "candidateMemberListId",
                type: {
                    name: "Number"
                }
            },
            tourCategory: {
                serializedName: "tourCategory",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            region: {
                serializedName: "region",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isMember: {
                serializedName: "isMember",
                type: {
                    name: "Boolean"
                }
            },
            tourId: {
                serializedName: "tourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            groupId: {
                serializedName: "groupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            idSeries: {
                serializedName: "idSeries",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            idNumber: {
                serializedName: "idNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            sex: {
                serializedName: "sex",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            departCityId: {
                serializedName: "departCityId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            memberId: {
                serializedName: "memberId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            member: {
                serializedName: "member",
                type: {
                    name: "Composite",
                    className: "Member"
                }
            },
            competition: {
                serializedName: "competition",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claimsCount: {
                serializedName: "claimsCount",
                type: {
                    name: "Number"
                }
            },
            memberAccessCount: {
                serializedName: "memberAccessCount",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const Int64NullableListApiResponse = {
    type: {
        name: "Composite",
        className: "Int64NullableListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Number"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const OkResponseApiResponse = {
    type: {
        name: "Composite",
        className: "OkResponseApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "OkResponse"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const OkResponse = {
    type: {
        name: "Composite",
        className: "OkResponse",
        modelProperties: {
            success: {
                serializedName: "success",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const CandidateMemberListParseModelApiResponse = {
    type: {
        name: "Composite",
        className: "CandidateMemberListParseModelApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "CandidateMemberListParseModel"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CandidateMemberListParseModel = {
    type: {
        name: "Composite",
        className: "CandidateMemberListParseModel",
        modelProperties: {
            candidates: {
                serializedName: "candidates",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CandidateMemberParseResult"
                        }
                    }
                }
            },
            guid: {
                serializedName: "guid",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            errors: {
                serializedName: "errors",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            }
        }
    }
};
export const CandidateMemberParseResult = {
    type: {
        name: "Composite",
        className: "CandidateMemberParseResult",
        modelProperties: {
            isValid: {
                serializedName: "isValid",
                type: {
                    name: "Boolean"
                }
            },
            errors: {
                serializedName: "errors",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            warnings: {
                serializedName: "warnings",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            userId: {
                serializedName: "userId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            birthDate: {
                serializedName: "birthDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            phoneNumber: {
                serializedName: "phoneNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            groupTourType: {
                serializedName: "groupTourType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            season: {
                serializedName: "season",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            status: {
                serializedName: "status",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberStatus: {
                serializedName: "memberStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            candidateMemberListId: {
                serializedName: "candidateMemberListId",
                type: {
                    name: "Number"
                }
            },
            tourCategory: {
                serializedName: "tourCategory",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            tourCategoryAmount: {
                serializedName: "tourCategoryAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            region: {
                serializedName: "region",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isMember: {
                serializedName: "isMember",
                type: {
                    name: "Boolean"
                }
            },
            tourId: {
                serializedName: "tourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            groupId: {
                serializedName: "groupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            idSeries: {
                serializedName: "idSeries",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            idNumber: {
                serializedName: "idNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            sex: {
                serializedName: "sex",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            departCityId: {
                serializedName: "departCityId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            memberId: {
                serializedName: "memberId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            member: {
                serializedName: "member",
                type: {
                    name: "Composite",
                    className: "Member"
                }
            },
            competition: {
                serializedName: "competition",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const CandidateMemberListCreateRequest = {
    type: {
        name: "Composite",
        className: "CandidateMemberListCreateRequest",
        modelProperties: {
            guid: {
                serializedName: "guid",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            projectId: {
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            budgetId: {
                serializedName: "budgetId",
                type: {
                    name: "Number"
                }
            },
            isSpecialProject: {
                serializedName: "isSpecialProject",
                type: {
                    name: "Boolean"
                }
            },
            isAutoLoad: {
                serializedName: "isAutoLoad",
                type: {
                    name: "Boolean"
                }
            },
            candidates: {
                serializedName: "candidates",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CandidateMember"
                        }
                    }
                }
            }
        }
    }
};
export const CandidateMemberListApiResponse = {
    type: {
        name: "Composite",
        className: "CandidateMemberListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "CandidateMemberList"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CandidateMemberICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "CandidateMemberICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CandidateMember"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CandidateMemberCreateRequest = {
    type: {
        name: "Composite",
        className: "CandidateMemberCreateRequest",
        modelProperties: {
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            candidateMemberListId: {
                serializedName: "candidateMemberListId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CandidateMemberApiResponse = {
    type: {
        name: "Composite",
        className: "CandidateMemberApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "CandidateMember"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CandidateMemberUpdateRequest = {
    type: {
        name: "Composite",
        className: "CandidateMemberUpdateRequest",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const GuidSuccessDtoApiResponse = {
    type: {
        name: "Composite",
        className: "GuidSuccessDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "GuidSuccessDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const GuidSuccessDto = {
    type: {
        name: "Composite",
        className: "GuidSuccessDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const DashboardCertificatePaymentRegistryItemListApiResponse = {
    type: {
        name: "Composite",
        className: "DashboardCertificatePaymentRegistryItemListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DashboardCertificatePaymentRegistryItem"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const DashboardCertificatePaymentRegistryItem = {
    type: {
        name: "Composite",
        className: "DashboardCertificatePaymentRegistryItem",
        modelProperties: {
            externalTourId: {
                serializedName: "externalTourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            externalTourGroupId: {
                serializedName: "externalTourGroupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourGroupId: {
                serializedName: "tourGroupId",
                type: {
                    name: "Uuid"
                }
            },
            externalSystemId: {
                serializedName: "externalSystemId",
                type: {
                    name: "Number"
                }
            },
            tourName: {
                serializedName: "tourName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourRegion: {
                serializedName: "tourRegion",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourType: {
                serializedName: "tourType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourTheme: {
                serializedName: "tourTheme",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourMotivation: {
                serializedName: "tourMotivation",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            occupancy: {
                serializedName: "occupancy",
                type: {
                    name: "Number"
                }
            },
            capacity: {
                serializedName: "capacity",
                type: {
                    name: "Number"
                }
            },
            minCapacity: {
                serializedName: "minCapacity",
                type: {
                    name: "Number"
                }
            },
            certificateAmount: {
                serializedName: "certificateAmount",
                type: {
                    name: "Number"
                }
            },
            claimsConfirmedWithoutRegistryTotalAmount: {
                serializedName: "claimsConfirmedWithoutRegistryTotalAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            tripDateBegin: {
                serializedName: "tripDateBegin",
                type: {
                    name: "DateTime"
                }
            },
            tripDateEnd: {
                serializedName: "tripDateEnd",
                type: {
                    name: "DateTime"
                }
            },
            claimsConfirmedTotalCount: {
                serializedName: "claimsConfirmedTotalCount",
                type: {
                    name: "Number"
                }
            },
            claimsInRegistryCount: {
                serializedName: "claimsInRegistryCount",
                type: {
                    name: "Number"
                }
            },
            claimsCancelationInRegistryCount: {
                serializedName: "claimsCancelationInRegistryCount",
                type: {
                    name: "Number"
                }
            },
            claimsConfirmedWithoutRegistryCount: {
                serializedName: "claimsConfirmedWithoutRegistryCount",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimsReplacementPaymentRegistryByGroupRequest = {
    type: {
        name: "Composite",
        className: "ClaimsReplacementPaymentRegistryByGroupRequest",
        modelProperties: {
            groupId: {
                serializedName: "groupId",
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const PaymentRegistryClaimItemWithReplacingClaimsListApiResponse = {
    type: {
        name: "Composite",
        className: "PaymentRegistryClaimItemWithReplacingClaimsListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "PaymentRegistryClaimItemWithReplacingClaims"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const PaymentRegistryClaimItemWithReplacingClaims = {
    type: {
        name: "Composite",
        className: "PaymentRegistryClaimItemWithReplacingClaims",
        modelProperties: {
            certificatePaymentRegistryItemId: {
                serializedName: "certificatePaymentRegistryItemId",
                type: {
                    name: "Number"
                }
            },
            registryNumber: {
                serializedName: "registryNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryDate: {
                serializedName: "registryDate",
                type: {
                    name: "DateTime"
                }
            },
            memberId: {
                serializedName: "memberId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            bookingCenterGroupId: {
                serializedName: "bookingCenterGroupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryClaimNumber: {
                serializedName: "registryClaimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryCertificateCode: {
                serializedName: "registryCertificateCode",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryAmount: {
                serializedName: "registryAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            replaceCount: {
                serializedName: "replaceCount",
                type: {
                    name: "Number"
                }
            },
            replacingClaimId: {
                serializedName: "replacingClaimId",
                type: {
                    name: "Uuid"
                }
            },
            replacingClaimNumber: {
                serializedName: "replacingClaimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            replacingProviderName: {
                serializedName: "replacingProviderName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            replacingMemberId: {
                serializedName: "replacingMemberId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            replacingClaimAmount: {
                serializedName: "replacingClaimAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            replacingTourDateBegin: {
                serializedName: "replacingTourDateBegin",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            replacingTourDateEnd: {
                serializedName: "replacingTourDateEnd",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const ClaimsReplacementPaymentRegistryByItemIdsRequest = {
    type: {
        name: "Composite",
        className: "ClaimsReplacementPaymentRegistryByItemIdsRequest",
        modelProperties: {
            items: {
                serializedName: "items",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Number"
                        }
                    }
                }
            }
        }
    }
};
export const DashboardCertificatePaymentRegistryClaimItemIListApiResponse = {
    type: {
        name: "Composite",
        className: "DashboardCertificatePaymentRegistryClaimItemIListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DashboardCertificatePaymentRegistryClaimItem"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const DashboardCertificatePaymentRegistryClaimItem = {
    type: {
        name: "Composite",
        className: "DashboardCertificatePaymentRegistryClaimItem",
        modelProperties: {
            certificatePaymentRegistryId: {
                serializedName: "certificatePaymentRegistryId",
                type: {
                    name: "Uuid"
                }
            },
            certificatePaymentRegistryItemId: {
                serializedName: "certificatePaymentRegistryItemId",
                type: {
                    name: "Number"
                }
            },
            registryCertificateCode: {
                serializedName: "registryCertificateCode",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberAccessCertificateCode: {
                serializedName: "memberAccessCertificateCode",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryAmount: {
                serializedName: "registryAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            registryNumber: {
                serializedName: "registryNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isCorrectionRegistry: {
                serializedName: "isCorrectionRegistry",
                type: {
                    name: "Boolean"
                }
            },
            registryMemberAccessId: {
                serializedName: "registryMemberAccessId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            memberId: {
                serializedName: "memberId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            memberAccessMemberId: {
                serializedName: "memberAccessMemberId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            registryDate: {
                serializedName: "registryDate",
                type: {
                    name: "DateTime"
                }
            },
            registryClaimNumber: {
                serializedName: "registryClaimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberAccessClaimNumber: {
                serializedName: "memberAccessClaimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterGroupId: {
                serializedName: "bookingCenterGroupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterClaimId: {
                serializedName: "bookingCenterClaimId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            bookingCenterClaimStatusName: {
                serializedName: "bookingCenterClaimStatusName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterClaimStatusId: {
                serializedName: "bookingCenterClaimStatusId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            totalAmount: {
                serializedName: "totalAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            budgetEnrollment: {
                serializedName: "budgetEnrollment",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            tourOperatorEnrollment: {
                serializedName: "tourOperatorEnrollment",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            tourDateBegin: {
                serializedName: "tourDateBegin",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            tourDateEnd: {
                serializedName: "tourDateEnd",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const DashboardReplacementRegistryAnyClaimsRequest = {
    type: {
        name: "Composite",
        className: "DashboardReplacementRegistryAnyClaimsRequest",
        modelProperties: {
            items: {
                serializedName: "items",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            }
        }
    }
};
export const DashboardReplacementCertificatePaymentRegistryClaimItemIListApiResponse = {
    type: {
        name: "Composite",
        className: "DashboardReplacementCertificatePaymentRegistryClaimItemIListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DashboardReplacementCertificatePaymentRegistryClaimItem"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const DashboardReplacementCertificatePaymentRegistryClaimItem = {
    type: {
        name: "Composite",
        className: "DashboardReplacementCertificatePaymentRegistryClaimItem",
        modelProperties: {
            certificatePaymentRegistryId: {
                serializedName: "certificatePaymentRegistryId",
                type: {
                    name: "Uuid"
                }
            },
            certificatePaymentRegistryItemId: {
                serializedName: "certificatePaymentRegistryItemId",
                type: {
                    name: "Number"
                }
            },
            registryCertificateCode: {
                serializedName: "registryCertificateCode",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberAccessCertificateCode: {
                serializedName: "memberAccessCertificateCode",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryAmount: {
                serializedName: "registryAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            registryNumber: {
                serializedName: "registryNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryMemberAccessId: {
                serializedName: "registryMemberAccessId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            memberId: {
                serializedName: "memberId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            memberAccessMemberId: {
                serializedName: "memberAccessMemberId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            registryDate: {
                serializedName: "registryDate",
                type: {
                    name: "DateTime"
                }
            },
            registryClaimNumber: {
                serializedName: "registryClaimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberAccessClaimNumber: {
                serializedName: "memberAccessClaimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterGroupId: {
                serializedName: "bookingCenterGroupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterClaimId: {
                serializedName: "bookingCenterClaimId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            bookingCenterClaimStatusName: {
                serializedName: "bookingCenterClaimStatusName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterClaimStatusId: {
                serializedName: "bookingCenterClaimStatusId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            totalAmount: {
                serializedName: "totalAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            budgetEnrollment: {
                serializedName: "budgetEnrollment",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            tourOperatorEnrollment: {
                serializedName: "tourOperatorEnrollment",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            tourDateBegin: {
                serializedName: "tourDateBegin",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            tourDateEnd: {
                serializedName: "tourDateEnd",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const DashboardNoRegistryClaimItemIListApiResponse = {
    type: {
        name: "Composite",
        className: "DashboardNoRegistryClaimItemIListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DashboardNoRegistryClaimItem"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const DashboardNoRegistryClaimItem = {
    type: {
        name: "Composite",
        className: "DashboardNoRegistryClaimItem",
        modelProperties: {
            bookingCenterClaimId: {
                serializedName: "bookingCenterClaimId",
                type: {
                    name: "Uuid"
                }
            },
            memberId: {
                serializedName: "memberId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            memberAccessId: {
                serializedName: "memberAccessId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterUserId: {
                serializedName: "bookingCenterUserId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterGroupId: {
                serializedName: "bookingCenterGroupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourId: {
                serializedName: "tourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            groupId: {
                serializedName: "groupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterClaimStatusName: {
                serializedName: "bookingCenterClaimStatusName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterClaimStatusId: {
                serializedName: "bookingCenterClaimStatusId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            totalAmount: {
                serializedName: "totalAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            budgetEnrollment: {
                serializedName: "budgetEnrollment",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            tourOperatorEnrollment: {
                serializedName: "tourOperatorEnrollment",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            tourDateBegin: {
                serializedName: "tourDateBegin",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            tourDateEnd: {
                serializedName: "tourDateEnd",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            memberAccessCertificate: {
                serializedName: "memberAccessCertificate",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const DashboardRegistryAnyClaimsRequest = {
    type: {
        name: "Composite",
        className: "DashboardRegistryAnyClaimsRequest",
        modelProperties: {
            items: {
                serializedName: "items",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            }
        }
    }
};
export const DashboardRegistryAnyClaimItemIListApiResponse = {
    type: {
        name: "Composite",
        className: "DashboardRegistryAnyClaimItemIListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DashboardRegistryAnyClaimItem"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const DashboardRegistryAnyClaimItem = {
    type: {
        name: "Composite",
        className: "DashboardRegistryAnyClaimItem",
        modelProperties: {
            bookingCenterClaimId: {
                serializedName: "bookingCenterClaimId",
                type: {
                    name: "Uuid"
                }
            },
            memberId: {
                serializedName: "memberId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            memberAccessId: {
                serializedName: "memberAccessId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterUserId: {
                serializedName: "bookingCenterUserId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterGroupId: {
                serializedName: "bookingCenterGroupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourId: {
                serializedName: "tourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            groupId: {
                serializedName: "groupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            countRegistries: {
                serializedName: "countRegistries",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterClaimStatusName: {
                serializedName: "bookingCenterClaimStatusName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterClaimStatusId: {
                serializedName: "bookingCenterClaimStatusId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            totalAmount: {
                serializedName: "totalAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            tourDateBegin: {
                serializedName: "tourDateBegin",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            tourDateEnd: {
                serializedName: "tourDateEnd",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            memberAccessCertificate: {
                serializedName: "memberAccessCertificate",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const CertificatePaymentRegistryDtoApiResponse = {
    type: {
        name: "Composite",
        className: "CertificatePaymentRegistryDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "CertificatePaymentRegistryDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CertificatePaymentRegistryDto = {
    type: {
        name: "Composite",
        className: "CertificatePaymentRegistryDto",
        modelProperties: {
            certificatePaymentRegistryId: {
                serializedName: "certificatePaymentRegistryId",
                type: {
                    name: "Uuid"
                }
            },
            number: {
                serializedName: "number",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            contract: {
                serializedName: "contract",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            date: {
                serializedName: "date",
                type: {
                    name: "DateTime"
                }
            },
            uploadDate: {
                serializedName: "uploadDate",
                type: {
                    name: "DateTime"
                }
            },
            certificatePaymentRegistryItems: {
                serializedName: "certificatePaymentRegistryItems",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CertificatePaymentRegistryItemNewDto"
                        }
                    }
                }
            }
        }
    }
};
export const CertificatePaymentRegistryItemNewDto = {
    type: {
        name: "Composite",
        className: "CertificatePaymentRegistryItemNewDto",
        modelProperties: {
            positionInDocument: {
                serializedName: "positionInDocument",
                type: {
                    name: "Number"
                }
            },
            fullName: {
                serializedName: "fullName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            certificateNumber: {
                serializedName: "certificateNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            identityDocumentNumber: {
                serializedName: "identityDocumentNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourOperatorNumber: {
                serializedName: "tourOperatorNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            cityArrival: {
                serializedName: "cityArrival",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            dateArrivalDepartureRaw: {
                serializedName: "dateArrivalDepartureRaw",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            dateArrival: {
                serializedName: "dateArrival",
                readOnly: true,
                type: {
                    name: "DateTime"
                }
            },
            dateDeparture: {
                serializedName: "dateDeparture",
                readOnly: true,
                type: {
                    name: "DateTime"
                }
            },
            accommodationFacility: {
                serializedName: "accommodationFacility",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            accommodationFacilityCategory: {
                serializedName: "accommodationFacilityCategory",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            transportationRoute: {
                serializedName: "transportationRoute",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            transportType: {
                serializedName: "transportType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tripDetails: {
                serializedName: "tripDetails",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            transfer: {
                serializedName: "transfer",
                type: {
                    name: "Boolean"
                }
            },
            excursionProgram: {
                serializedName: "excursionProgram",
                type: {
                    name: "Boolean"
                }
            },
            tourOperator: {
                serializedName: "tourOperator",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            price: {
                serializedName: "price",
                type: {
                    name: "Number"
                }
            },
            registryType: {
                serializedName: "registryType",
                type: {
                    name: "String"
                }
            },
            replacedRegistryNumber: {
                serializedName: "replacedRegistryNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            replacedPositionRowNumber: {
                serializedName: "replacedPositionRowNumber",
                type: {
                    name: "Number"
                }
            },
            replacedCertificationCode: {
                serializedName: "replacedCertificationCode",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            replacedMemberId: {
                serializedName: "replacedMemberId",
                type: {
                    name: "Number"
                }
            },
            replacedClaimNumber: {
                serializedName: "replacedClaimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            replacedPrice: {
                serializedName: "replacedPrice",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CertificatePaymentRegistryListItemDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "CertificatePaymentRegistryListItemDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CertificatePaymentRegistryListItemDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CertificatePaymentRegistryListItemDto = {
    type: {
        name: "Composite",
        className: "CertificatePaymentRegistryListItemDto",
        modelProperties: {
            certificatePaymentRegistryId: {
                serializedName: "certificatePaymentRegistryId",
                type: {
                    name: "Uuid"
                }
            },
            number: {
                serializedName: "number",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isCorrectionRegistry: {
                serializedName: "isCorrectionRegistry",
                type: {
                    name: "Boolean"
                }
            },
            contract: {
                serializedName: "contract",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryDate: {
                serializedName: "registryDate",
                type: {
                    name: "DateTime"
                }
            },
            sumRowsAmount: {
                serializedName: "sumRowsAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            rowsCount: {
                serializedName: "rowsCount",
                type: {
                    name: "Number"
                }
            },
            budgetId: {
                serializedName: "budgetId",
                type: {
                    name: "Number"
                }
            },
            budgetName: {
                serializedName: "budgetName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            programId: {
                serializedName: "programId",
                type: {
                    name: "Number"
                }
            },
            programName: {
                serializedName: "programName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            uploadDate: {
                serializedName: "uploadDate",
                type: {
                    name: "DateTime"
                }
            },
            isAccepted: {
                serializedName: "isAccepted",
                type: {
                    name: "Boolean"
                }
            },
            statusName: {
                serializedName: "statusName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            certificatePaymentRegistryStatusId: {
                serializedName: "certificatePaymentRegistryStatusId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CertificatePaymentRegistryFullDtoApiResponse = {
    type: {
        name: "Composite",
        className: "CertificatePaymentRegistryFullDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "CertificatePaymentRegistryFullDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CertificatePaymentRegistryFullDto = {
    type: {
        name: "Composite",
        className: "CertificatePaymentRegistryFullDto",
        modelProperties: {
            certificatePaymentRegistryId: {
                serializedName: "certificatePaymentRegistryId",
                type: {
                    name: "Uuid"
                }
            },
            number: {
                serializedName: "number",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            contract: {
                serializedName: "contract",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryDate: {
                serializedName: "registryDate",
                type: {
                    name: "DateTime"
                }
            },
            isCorrectionRegistry: {
                serializedName: "isCorrectionRegistry",
                type: {
                    name: "Boolean"
                }
            },
            budgetId: {
                serializedName: "budgetId",
                type: {
                    name: "Number"
                }
            },
            budgetName: {
                serializedName: "budgetName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            programId: {
                serializedName: "programId",
                type: {
                    name: "Number"
                }
            },
            programName: {
                serializedName: "programName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            uploadDate: {
                serializedName: "uploadDate",
                type: {
                    name: "DateTime"
                }
            },
            isAccepted: {
                serializedName: "isAccepted",
                type: {
                    name: "Boolean"
                }
            },
            statusName: {
                serializedName: "statusName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            certificatePaymentRegistryStatusId: {
                serializedName: "certificatePaymentRegistryStatusId",
                type: {
                    name: "Number"
                }
            },
            registryType: {
                serializedName: "registryType",
                type: {
                    name: "String"
                }
            },
            certificatePaymentRegistryItems: {
                serializedName: "certificatePaymentRegistryItems",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CertificatePaymentRegistryItemDto"
                        }
                    }
                }
            }
        }
    }
};
export const CertificatePaymentRegistryItemDto = {
    type: {
        name: "Composite",
        className: "CertificatePaymentRegistryItemDto",
        modelProperties: {
            certificatePaymentRegistryItemId: {
                serializedName: "certificatePaymentRegistryItemId",
                type: {
                    name: "Number"
                }
            },
            certificatePaymentRegistryId: {
                serializedName: "certificatePaymentRegistryId",
                type: {
                    name: "Uuid"
                }
            },
            amount: {
                serializedName: "amount",
                type: {
                    name: "Number"
                }
            },
            certificateId: {
                serializedName: "certificateId",
                type: {
                    name: "Number"
                }
            },
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            memberAccessId: {
                serializedName: "memberAccessId",
                type: {
                    name: "Number"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            exchangeType: {
                serializedName: "exchangeType",
                type: {
                    name: "String"
                }
            },
            metadata: {
                serializedName: "metadata",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            metadataJson: {
                serializedName: "metadataJson",
                readOnly: true,
                nullable: true,
                type: {
                    name: "any"
                }
            }
        }
    }
};
export const ByteKeyValueDtoListApiResponse = {
    type: {
        name: "Composite",
        className: "ByteKeyValueDtoListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ByteKeyValueDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ByteKeyValueDto = {
    type: {
        name: "Composite",
        className: "ByteKeyValueDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const PreCreateRegistryItemDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "PreCreateRegistryItemDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "PreCreateRegistryItemDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const PreCreateRegistryItemDto = {
    type: {
        name: "Composite",
        className: "PreCreateRegistryItemDto",
        modelProperties: {
            candidateMemberId: {
                serializedName: "candidateMemberId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            memberId: {
                serializedName: "memberId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            patronymic: {
                serializedName: "patronymic",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fullName: {
                serializedName: "fullName",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            identityDocumentNumber: {
                serializedName: "identityDocumentNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            birthDate: {
                serializedName: "birthDate",
                type: {
                    name: "DateTime"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            certificate: {
                serializedName: "certificate",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            price: {
                serializedName: "price",
                type: {
                    name: "Number"
                }
            },
            dateBegin: {
                serializedName: "dateBegin",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            dateEnd: {
                serializedName: "dateEnd",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const PaymentRegistryGenerateByGroupRequest = {
    type: {
        name: "Composite",
        className: "PaymentRegistryGenerateByGroupRequest",
        modelProperties: {
            groupId: {
                serializedName: "groupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryNumber: {
                serializedName: "registryNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryDate: {
                serializedName: "registryDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const PaymentRegistryGenerateByClaimsRequest = {
    type: {
        name: "Composite",
        className: "PaymentRegistryGenerateByClaimsRequest",
        modelProperties: {
            claimIds: {
                serializedName: "claimIds",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Uuid"
                        }
                    }
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryNumber: {
                serializedName: "registryNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryDate: {
                serializedName: "registryDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const CorrectingPaymentRegistryGenerateRequest = {
    type: {
        name: "Composite",
        className: "CorrectingPaymentRegistryGenerateRequest",
        modelProperties: {
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryNumber: {
                serializedName: "registryNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryDate: {
                serializedName: "registryDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            items: {
                serializedName: "items",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CorrectingPaymentRegistryGenerateRequestItem"
                        }
                    }
                }
            }
        }
    }
};
export const CorrectingPaymentRegistryGenerateRequestItem = {
    type: {
        name: "Composite",
        className: "CorrectingPaymentRegistryGenerateRequestItem",
        modelProperties: {
            registryItemId: {
                serializedName: "registryItemId",
                type: {
                    name: "Number"
                }
            },
            claimId: {
                serializedName: "claimId",
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const ApplicationRegistryLoadResultApiResponse = {
    type: {
        name: "Composite",
        className: "ApplicationRegistryLoadResultApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ApplicationRegistryLoadResult"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ApplicationRegistryLoadResult = {
    type: {
        name: "Composite",
        className: "ApplicationRegistryLoadResult",
        modelProperties: {
            addedApplications: {
                serializedName: "addedApplications",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CertificatePaymentRegistryItemNewDto"
                        }
                    }
                }
            },
            updatedApplications: {
                serializedName: "updatedApplications",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CertificatePaymentRegistryItemNewDto"
                        }
                    }
                }
            }
        }
    }
};
export const CertificateDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "CertificateDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CertificateDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CertificateDto = {
    type: {
        name: "Composite",
        className: "CertificateDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            initialAmount: {
                serializedName: "initialAmount",
                type: {
                    name: "Number"
                }
            },
            currentAmount: {
                serializedName: "currentAmount",
                type: {
                    name: "Number"
                }
            },
            certificateIsBlocked: {
                serializedName: "certificateIsBlocked",
                type: {
                    name: "Boolean"
                }
            },
            countAcceptedCertificatePaymentRegistries: {
                serializedName: "countAcceptedCertificatePaymentRegistries",
                type: {
                    name: "Number"
                }
            },
            countClaimsInRegistries: {
                serializedName: "countClaimsInRegistries",
                type: {
                    name: "Number"
                }
            },
            countClaims: {
                serializedName: "countClaims",
                type: {
                    name: "Number"
                }
            },
            countConfirmedClaimsInRegistries: {
                serializedName: "countConfirmedClaimsInRegistries",
                type: {
                    name: "Number"
                }
            },
            totalSpentAmount: {
                serializedName: "totalSpentAmount",
                type: {
                    name: "Number"
                }
            },
            isUsed: {
                serializedName: "isUsed",
                readOnly: true,
                type: {
                    name: "Boolean"
                }
            },
            isFreeAmount: {
                serializedName: "isFreeAmount",
                readOnly: true,
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const CertificateCreateRequest = {
    type: {
        name: "Composite",
        className: "CertificateCreateRequest",
        modelProperties: {
            code: {
                serializedName: "code",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            initialAmount: {
                serializedName: "initialAmount",
                type: {
                    name: "Number"
                }
            },
            currentAmount: {
                serializedName: "currentAmount",
                type: {
                    name: "Number"
                }
            },
            uploudUserId: {
                serializedName: "uploudUserId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            currentMemberId: {
                serializedName: "currentMemberId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CertificateApiResponse = {
    type: {
        name: "Composite",
        className: "CertificateApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "Certificate"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const Certificate = {
    type: {
        name: "Composite",
        className: "Certificate",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            initialAmount: {
                serializedName: "initialAmount",
                type: {
                    name: "Number"
                }
            },
            currentAmount: {
                serializedName: "currentAmount",
                type: {
                    name: "Number"
                }
            },
            uploudUserId: {
                serializedName: "uploudUserId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            currentMemberId: {
                serializedName: "currentMemberId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CertificateUpdateRequest = {
    type: {
        name: "Composite",
        className: "CertificateUpdateRequest",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            initialAmount: {
                serializedName: "initialAmount",
                type: {
                    name: "Number"
                }
            },
            currentAmount: {
                serializedName: "currentAmount",
                type: {
                    name: "Number"
                }
            },
            uploudUserId: {
                serializedName: "uploudUserId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            currentMemberId: {
                serializedName: "currentMemberId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CertificateDetailedDtoApiResponse = {
    type: {
        name: "Composite",
        className: "CertificateDetailedDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "CertificateDetailedDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CertificateDetailedDto = {
    type: {
        name: "Composite",
        className: "CertificateDetailedDto",
        modelProperties: {
            certificateIsBloked: {
                serializedName: "certificateIsBloked",
                nullable: true,
                type: {
                    name: "Boolean"
                }
            },
            budget: {
                serializedName: "budget",
                type: {
                    name: "Composite",
                    className: "CertificateDetailedBudgetDto"
                }
            },
            memberAccesses: {
                serializedName: "memberAccesses",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MemberAccessDto"
                        }
                    }
                }
            },
            certificatePaymentRegistryItems: {
                serializedName: "certificatePaymentRegistryItems",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CertificatePaymentRegistryItemForCeritificateDto"
                        }
                    }
                }
            },
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            initialAmount: {
                serializedName: "initialAmount",
                type: {
                    name: "Number"
                }
            },
            currentAmount: {
                serializedName: "currentAmount",
                type: {
                    name: "Number"
                }
            },
            uploudUserId: {
                serializedName: "uploudUserId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            currentMemberId: {
                serializedName: "currentMemberId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CertificateDetailedBudgetDto = {
    type: {
        name: "Composite",
        className: "CertificateDetailedBudgetDto",
        modelProperties: {
            budgetId: {
                serializedName: "budgetId",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            amount: {
                serializedName: "amount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            contractNumber: {
                serializedName: "contractNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const MemberAccessDto = {
    type: {
        name: "Composite",
        className: "MemberAccessDto",
        modelProperties: {
            memberAccessId: {
                serializedName: "memberAccessId",
                type: {
                    name: "Number"
                }
            },
            certificateInitialAmount: {
                serializedName: "certificateInitialAmount",
                type: {
                    name: "Number"
                }
            },
            currentCertificateId: {
                serializedName: "currentCertificateId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            certificateConfirmed: {
                serializedName: "certificateConfirmed",
                type: {
                    name: "Boolean"
                }
            },
            currentClaimNumber: {
                serializedName: "currentClaimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            certificateSpentAmount: {
                serializedName: "certificateSpentAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            accessIsBlocked: {
                serializedName: "accessIsBlocked",
                type: {
                    name: "Boolean"
                }
            },
            accessExpireDate: {
                serializedName: "accessExpireDate",
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const CertificatePaymentRegistryItemForCeritificateDto = {
    type: {
        name: "Composite",
        className: "CertificatePaymentRegistryItemForCeritificateDto",
        modelProperties: {
            certificatePaymentRegistryItemId: {
                serializedName: "certificatePaymentRegistryItemId",
                type: {
                    name: "Number"
                }
            },
            amount: {
                serializedName: "amount",
                type: {
                    name: "Number"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            exchangeType: {
                serializedName: "exchangeType",
                type: {
                    name: "Number"
                }
            },
            certificatePaymentRegistry: {
                serializedName: "certificatePaymentRegistry",
                type: {
                    name: "Composite",
                    className: "CertificatePaymentRegistryByCertificateDto"
                }
            },
            memberAccess: {
                serializedName: "memberAccess",
                type: {
                    name: "Composite",
                    className: "MemberAccessDto"
                }
            },
            member: {
                serializedName: "member",
                type: {
                    name: "Composite",
                    className: "MemberDto"
                }
            }
        }
    }
};
export const CertificatePaymentRegistryByCertificateDto = {
    type: {
        name: "Composite",
        className: "CertificatePaymentRegistryByCertificateDto",
        modelProperties: {
            certificatePaymentRegistryId: {
                serializedName: "certificatePaymentRegistryId",
                type: {
                    name: "Uuid"
                }
            },
            budgetId: {
                serializedName: "budgetId",
                type: {
                    name: "Number"
                }
            },
            registryNumber: {
                serializedName: "registryNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isAccepted: {
                serializedName: "isAccepted",
                type: {
                    name: "Boolean"
                }
            },
            registryDate: {
                serializedName: "registryDate",
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const MemberDto = {
    type: {
        name: "Composite",
        className: "MemberDto",
        modelProperties: {
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isBlocked: {
                serializedName: "isBlocked",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const CertificateICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "CertificateICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "Certificate"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CertificateAttachRequest = {
    type: {
        name: "Composite",
        className: "CertificateAttachRequest",
        modelProperties: {
            claimNumber: {
                constraints: {
                    MinLength: 1
                },
                serializedName: "claimNumber",
                required: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterClaimId: {
                serializedName: "bookingCenterClaimId",
                required: true,
                type: {
                    name: "Uuid"
                }
            },
            memberAccessId: {
                serializedName: "memberAccessId",
                required: true,
                type: {
                    name: "Number"
                }
            },
            certificateId: {
                serializedName: "certificateId",
                required: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CertificateRequest = {
    type: {
        name: "Composite",
        className: "CertificateRequest",
        modelProperties: {
            certificateId: {
                serializedName: "certificateId",
                required: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CertificateTransferRequest = {
    type: {
        name: "Composite",
        className: "CertificateTransferRequest",
        modelProperties: {
            claimNumber: {
                constraints: {
                    MinLength: 1
                },
                serializedName: "claimNumber",
                required: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterClaimId: {
                serializedName: "bookingCenterClaimId",
                required: true,
                type: {
                    name: "Uuid"
                }
            },
            memberAccessId: {
                serializedName: "memberAccessId",
                required: true,
                type: {
                    name: "Number"
                }
            },
            certificateId: {
                serializedName: "certificateId",
                required: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimFileDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "ClaimFileDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ClaimFileDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimFileDto = {
    type: {
        name: "Composite",
        className: "ClaimFileDto",
        modelProperties: {
            url: {
                serializedName: "url",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fileName: {
                serializedName: "fileName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fileDate: {
                serializedName: "fileDate",
                type: {
                    name: "DateTime"
                }
            },
            contentLength: {
                serializedName: "contentLength",
                type: {
                    name: "Number"
                }
            },
            fileTypeCode: {
                serializedName: "fileTypeCode",
                type: {
                    name: "Number"
                }
            },
            fileTypeDescription: {
                serializedName: "fileTypeDescription",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fileId: {
                serializedName: "fileId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const ClaimsDocumentsArchiveRequest = {
    type: {
        name: "Composite",
        className: "ClaimsDocumentsArchiveRequest",
        modelProperties: {
            claimIds: {
                serializedName: "claimIds",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Uuid"
                        }
                    }
                }
            },
            sourceIds: {
                serializedName: "sourceIds",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Number"
                        }
                    }
                }
            },
            fileName: {
                serializedName: "fileName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ClaimDtoListApiResponse = {
    type: {
        name: "Composite",
        className: "ClaimDtoListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ClaimDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimDto = {
    type: {
        name: "Composite",
        className: "ClaimDto",
        modelProperties: {
            dateBegin: {
                serializedName: "dateBegin",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            dateEnd: {
                serializedName: "dateEnd",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            customerPrice: {
                serializedName: "customerPrice",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            tourOperatorEnrollment: {
                serializedName: "tourOperatorEnrollment",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            ticketAmount: {
                serializedName: "ticketAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            totalAmount: {
                serializedName: "totalAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            producerPrice: {
                serializedName: "producerPrice",
                type: {
                    name: "Number"
                }
            },
            calcTourOperatorDebt: {
                serializedName: "calcTourOperatorDebt",
                type: {
                    name: "Number"
                }
            },
            bookingCenterClaimId: {
                serializedName: "bookingCenterClaimId",
                type: {
                    name: "Uuid"
                }
            },
            tourGroupId: {
                serializedName: "tourGroupId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            tourId: {
                serializedName: "tourId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            memberId: {
                serializedName: "memberId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            userId: {
                serializedName: "userId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            contactEmail: {
                serializedName: "contactEmail",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            contactPhone: {
                serializedName: "contactPhone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            documentsSendingStatus: {
                serializedName: "documentsSendingStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            documentsSendingStatusCode: {
                serializedName: "documentsSendingStatusCode",
                type: {
                    name: "Number"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fullName: {
                serializedName: "fullName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            status: {
                serializedName: "status",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                type: {
                    name: "String"
                }
            },
            tour: {
                serializedName: "tour",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourGroup: {
                serializedName: "tourGroup",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            townFrom: {
                serializedName: "townFrom",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            birthDate: {
                serializedName: "birthDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            isSelfPaymentClaim: {
                serializedName: "isSelfPaymentClaim",
                type: {
                    name: "Boolean"
                }
            },
            createDate: {
                serializedName: "createDate",
                type: {
                    name: "DateTime"
                }
            },
            absent: {
                serializedName: "absent",
                type: {
                    name: "Boolean"
                }
            },
            unreadMessagesCount: {
                serializedName: "unreadMessagesCount",
                type: {
                    name: "Number"
                }
            },
            memberAccessId: {
                serializedName: "memberAccessId",
                readOnly: true,
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            providerId: {
                serializedName: "providerId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            canBeConfirmed: {
                serializedName: "canBeConfirmed",
                type: {
                    name: "Boolean"
                }
            },
            nightsCount: {
                serializedName: "nightsCount",
                type: {
                    name: "Number"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ClaimDetailedDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ClaimDetailedDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ClaimDetailedDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimDetailedDto = {
    type: {
        name: "Composite",
        className: "ClaimDetailedDto",
        modelProperties: {
            birthDate: {
                serializedName: "birthDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            identityDocumentIssueDate: {
                serializedName: "identityDocumentIssueDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            budgetId: {
                serializedName: "budgetId",
                type: {
                    name: "Number"
                }
            },
            claimFiles: {
                serializedName: "claimFiles",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ClaimFileDto"
                        }
                    }
                }
            },
            citizenship: {
                serializedName: "citizenship",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claimDocumentsComment: {
                serializedName: "claimDocumentsComment",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            identityDocumentIdentificationNumber: {
                serializedName: "identityDocumentIdentificationNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            identityDocumentSerialNumber: {
                serializedName: "identityDocumentSerialNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            parentContactPhone: {
                serializedName: "parentContactPhone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            parentFirstName: {
                serializedName: "parentFirstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            sex: {
                serializedName: "sex",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            dateBegin: {
                serializedName: "dateBegin",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            dateEnd: {
                serializedName: "dateEnd",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            customerPrice: {
                serializedName: "customerPrice",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            tourOperatorEnrollment: {
                serializedName: "tourOperatorEnrollment",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            ticketAmount: {
                serializedName: "ticketAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            totalAmount: {
                serializedName: "totalAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            producerPrice: {
                serializedName: "producerPrice",
                type: {
                    name: "Number"
                }
            },
            calcTourOperatorDebt: {
                serializedName: "calcTourOperatorDebt",
                type: {
                    name: "Number"
                }
            },
            bookingCenterClaimId: {
                serializedName: "bookingCenterClaimId",
                type: {
                    name: "Uuid"
                }
            },
            tourGroupId: {
                serializedName: "tourGroupId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            tourId: {
                serializedName: "tourId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            memberId: {
                serializedName: "memberId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            userId: {
                serializedName: "userId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            contactEmail: {
                serializedName: "contactEmail",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            contactPhone: {
                serializedName: "contactPhone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            documentsSendingStatus: {
                serializedName: "documentsSendingStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            documentsSendingStatusCode: {
                serializedName: "documentsSendingStatusCode",
                type: {
                    name: "Number"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fullName: {
                serializedName: "fullName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            status: {
                serializedName: "status",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                type: {
                    name: "String"
                }
            },
            tour: {
                serializedName: "tour",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourGroup: {
                serializedName: "tourGroup",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            townFrom: {
                serializedName: "townFrom",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isSelfPaymentClaim: {
                serializedName: "isSelfPaymentClaim",
                type: {
                    name: "Boolean"
                }
            },
            createDate: {
                serializedName: "createDate",
                type: {
                    name: "DateTime"
                }
            },
            absent: {
                serializedName: "absent",
                type: {
                    name: "Boolean"
                }
            },
            unreadMessagesCount: {
                serializedName: "unreadMessagesCount",
                type: {
                    name: "Number"
                }
            },
            memberAccessId: {
                serializedName: "memberAccessId",
                readOnly: true,
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            providerId: {
                serializedName: "providerId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            canBeConfirmed: {
                serializedName: "canBeConfirmed",
                type: {
                    name: "Boolean"
                }
            },
            nightsCount: {
                serializedName: "nightsCount",
                type: {
                    name: "Number"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const DocumentsSendingStatusDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "DocumentsSendingStatusDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DocumentsSendingStatusDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const DocumentsSendingStatusDto = {
    type: {
        name: "Composite",
        className: "DocumentsSendingStatusDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            label: {
                serializedName: "label",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ClaimDetailedDtoListApiResponse = {
    type: {
        name: "Composite",
        className: "ClaimDetailedDtoListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ClaimDetailedDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimAmountsDto = {
    type: {
        name: "Composite",
        className: "ClaimAmountsDto",
        modelProperties: {
            totalAmount: {
                serializedName: "totalAmount",
                required: true,
                type: {
                    name: "Number"
                }
            },
            ticketAmount: {
                serializedName: "ticketAmount",
                required: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimSummaryCountsApiResponse = {
    type: {
        name: "Composite",
        className: "ClaimSummaryCountsApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ClaimSummaryCounts"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimSummaryCounts = {
    type: {
        name: "Composite",
        className: "ClaimSummaryCounts",
        modelProperties: {
            totalCount: {
                serializedName: "totalCount",
                type: {
                    name: "Number"
                }
            },
            confirmed: {
                serializedName: "confirmed",
                type: {
                    name: "Number"
                }
            },
            booked: {
                serializedName: "booked",
                type: {
                    name: "Number"
                }
            },
            cancellationRequested: {
                serializedName: "cancellationRequested",
                type: {
                    name: "Number"
                }
            },
            canceledWithFines: {
                serializedName: "canceledWithFines",
                type: {
                    name: "Number"
                }
            },
            bookingInProgress: {
                serializedName: "bookingInProgress",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const DataFilter = {
    type: {
        name: "Composite",
        className: "DataFilter",
        modelProperties: {
            dataFilters: {
                serializedName: "dataFilters",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DataFilterItem"
                        }
                    }
                }
            },
            orderBy: {
                serializedName: "orderBy",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            sortOrder: {
                serializedName: "sortOrder",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            pageNumber: {
                serializedName: "pageNumber",
                type: {
                    name: "Number"
                }
            },
            pageSize: {
                serializedName: "pageSize",
                type: {
                    name: "Number"
                }
            },
            searchString: {
                serializedName: "searchString",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const DataFilterItem = {
    type: {
        name: "Composite",
        className: "DataFilterItem",
        modelProperties: {
            fieldName: {
                serializedName: "fieldName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            operator: {
                serializedName: "operator",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fieldValue: {
                serializedName: "fieldValue",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ClaimDtoPaginateApiResponse = {
    type: {
        name: "Composite",
        className: "ClaimDtoPaginateApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ClaimDtoPaginate"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimDtoPaginate = {
    type: {
        name: "Composite",
        className: "ClaimDtoPaginate",
        modelProperties: {
            data: {
                serializedName: "data",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ClaimDto"
                        }
                    }
                }
            },
            pageSize: {
                serializedName: "pageSize",
                type: {
                    name: "Number"
                }
            },
            page: {
                serializedName: "page",
                type: {
                    name: "Number"
                }
            },
            count: {
                serializedName: "count",
                type: {
                    name: "Number"
                }
            },
            optionalSummaries: {
                serializedName: "optionalSummaries",
                nullable: true,
                type: {
                    name: "Dictionary",
                    value: { type: { name: "String" } }
                }
            }
        }
    }
};
export const ClientAppICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "ClientAppICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ClientApp"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClientApp = {
    type: {
        name: "Composite",
        className: "ClientApp",
        modelProperties: {
            clientId: {
                serializedName: "clientId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            displayName: {
                serializedName: "displayName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ClientAppCreateRequest = {
    type: {
        name: "Composite",
        className: "ClientAppCreateRequest",
        modelProperties: {
            clientId: {
                serializedName: "clientId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            secret: {
                serializedName: "secret",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ClientAppApiResponse = {
    type: {
        name: "Composite",
        className: "ClientAppApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ClientApp"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CompetitionDtoListApiResponse = {
    type: {
        name: "Composite",
        className: "CompetitionDtoListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CompetitionDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CompetitionDto = {
    type: {
        name: "Composite",
        className: "CompetitionDto",
        modelProperties: {
            competitionId: {
                serializedName: "competitionId",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const CreateCompetitionRequest = {
    type: {
        name: "Composite",
        className: "CreateCompetitionRequest",
        modelProperties: {
            competitionId: {
                serializedName: "competitionId",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const TourGroupDtoApiResponse = {
    type: {
        name: "Composite",
        className: "TourGroupDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "TourGroupDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const TourGroupDto = {
    type: {
        name: "Composite",
        className: "TourGroupDto",
        modelProperties: {
            tourGroupId: {
                serializedName: "tourGroupId",
                type: {
                    name: "Uuid"
                }
            },
            externalTourGroupId: {
                serializedName: "externalTourGroupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourId: {
                serializedName: "tourId",
                type: {
                    name: "Uuid"
                }
            },
            externalTourId: {
                serializedName: "externalTourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourName: {
                serializedName: "tourName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourType: {
                serializedName: "tourType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourCost: {
                serializedName: "tourCost",
                type: {
                    name: "Number"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            start: {
                serializedName: "start",
                type: {
                    name: "DateTime"
                }
            },
            end: {
                serializedName: "end",
                type: {
                    name: "DateTime"
                }
            },
            claimsConfirmed: {
                serializedName: "claimsConfirmed",
                type: {
                    name: "Number"
                }
            },
            claimsPendingAccept: {
                serializedName: "claimsPendingAccept",
                type: {
                    name: "Number"
                }
            },
            claimsPendingCancel: {
                serializedName: "claimsPendingCancel",
                type: {
                    name: "Number"
                }
            },
            exportAvailable: {
                serializedName: "exportAvailable",
                type: {
                    name: "Boolean"
                }
            },
            minTouristCount: {
                serializedName: "minTouristCount",
                type: {
                    name: "Number"
                }
            },
            maxTouristCount: {
                serializedName: "maxTouristCount",
                type: {
                    name: "Number"
                }
            },
            telegramLink: {
                serializedName: "telegramLink",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isDeleted: {
                serializedName: "isDeleted",
                type: {
                    name: "Boolean"
                }
            },
            overBookCount: {
                serializedName: "overBookCount",
                readOnly: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CompetitionDtoApiResponse = {
    type: {
        name: "Composite",
        className: "CompetitionDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "CompetitionDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const UpdateCompetitionRequest = {
    type: {
        name: "Composite",
        className: "UpdateCompetitionRequest",
        modelProperties: {
            competitionId: {
                serializedName: "competitionId",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ClaimMessageDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "ClaimMessageDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ClaimMessageDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimMessageDto = {
    type: {
        name: "Composite",
        className: "ClaimMessageDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            claimId: {
                serializedName: "claimId",
                type: {
                    name: "Uuid"
                }
            },
            userName: {
                serializedName: "userName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDate: {
                serializedName: "createDate",
                type: {
                    name: "DateTime"
                }
            },
            content: {
                serializedName: "content",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            messageSourceId: {
                serializedName: "messageSourceId",
                type: {
                    name: "Number"
                }
            },
            messageStatusId: {
                serializedName: "messageStatusId",
                type: {
                    name: "Number"
                }
            },
            departmentMessageTypeId: {
                serializedName: "departmentMessageTypeId",
                type: {
                    name: "Number"
                }
            },
            originalDepartmentMessageTypeId: {
                serializedName: "originalDepartmentMessageTypeId",
                type: {
                    name: "Number"
                }
            },
            managerId: {
                serializedName: "managerId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            managerName: {
                serializedName: "managerName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            senderUserId: {
                serializedName: "senderUserId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            version: {
                serializedName: "version",
                type: {
                    name: "Number"
                }
            },
            senderManagerCbDepartmentId: {
                serializedName: "senderManagerCbDepartmentId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            visibilityGroupMaskId: {
                serializedName: "visibilityGroupMaskId",
                type: {
                    name: "Number"
                }
            },
            fromTourOperatorDate: {
                serializedName: "fromTourOperatorDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            translateToTourOperator: {
                serializedName: "translateToTourOperator",
                type: {
                    name: "Boolean"
                }
            },
            files: {
                serializedName: "files",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "TourBookingFileDto"
                        }
                    }
                }
            }
        }
    }
};
export const TourBookingFileDto = {
    type: {
        name: "Composite",
        className: "TourBookingFileDto",
        modelProperties: {
            fileStorageId: {
                serializedName: "fileStorageId",
                type: {
                    name: "Uuid"
                }
            },
            fileTypeId: {
                serializedName: "fileTypeId",
                type: {
                    name: "Number"
                }
            },
            fileUploadSource: {
                serializedName: "fileUploadSource",
                type: {
                    name: "Number"
                }
            },
            fileType: {
                serializedName: "fileType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDate: {
                serializedName: "createDate",
                type: {
                    name: "DateTime"
                }
            },
            fileAttributeExpiresDateTimeUtc: {
                serializedName: "fileAttributeExpiresDateTimeUtc",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            fileAttributeRequireAuthorization: {
                serializedName: "fileAttributeRequireAuthorization",
                type: {
                    name: "Boolean"
                }
            },
            fileAttributeFileSizeByte: {
                serializedName: "fileAttributeFileSizeByte",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            fileLink: {
                serializedName: "fileLink",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ClaimMessageDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ClaimMessageDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ClaimMessageDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimMessageCreateRequest = {
    type: {
        name: "Composite",
        className: "ClaimMessageCreateRequest",
        modelProperties: {
            claimId: {
                serializedName: "claimId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            messageContent: {
                serializedName: "messageContent",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourBookingFiles: {
                serializedName: "tourBookingFiles",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "TourBookingFileDto"
                        }
                    }
                }
            }
        }
    }
};
export const TourBookingFileDtoApiResponse = {
    type: {
        name: "Composite",
        className: "TourBookingFileDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "TourBookingFileDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const DashboardListItemPaginateApiResponse = {
    type: {
        name: "Composite",
        className: "DashboardListItemPaginateApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "DashboardListItemPaginate"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const DashboardListItemPaginate = {
    type: {
        name: "Composite",
        className: "DashboardListItemPaginate",
        modelProperties: {
            data: {
                serializedName: "data",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DashboardListItem"
                        }
                    }
                }
            },
            pageSize: {
                serializedName: "pageSize",
                type: {
                    name: "Number"
                }
            },
            page: {
                serializedName: "page",
                type: {
                    name: "Number"
                }
            },
            count: {
                serializedName: "count",
                type: {
                    name: "Number"
                }
            },
            optionalSummaries: {
                serializedName: "optionalSummaries",
                nullable: true,
                type: {
                    name: "Dictionary",
                    value: { type: { name: "String" } }
                }
            }
        }
    }
};
export const DashboardListItem = {
    type: {
        name: "Composite",
        className: "DashboardListItem",
        modelProperties: {
            externalTourId: {
                serializedName: "externalTourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            externalTourGroupId: {
                serializedName: "externalTourGroupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            externalSystemId: {
                serializedName: "externalSystemId",
                type: {
                    name: "Number"
                }
            },
            tourName: {
                serializedName: "tourName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourRegion: {
                serializedName: "tourRegion",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourType: {
                serializedName: "tourType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourTheme: {
                serializedName: "tourTheme",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourMotivation: {
                serializedName: "tourMotivation",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            occupancy: {
                serializedName: "occupancy",
                type: {
                    name: "Number"
                }
            },
            occupancyPercentage: {
                serializedName: "occupancyPercentage",
                type: {
                    name: "Number"
                }
            },
            capacity: {
                serializedName: "capacity",
                type: {
                    name: "Number"
                }
            },
            minCapacity: {
                serializedName: "minCapacity",
                type: {
                    name: "Number"
                }
            },
            certificateAmount: {
                serializedName: "certificateAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            adultCount: {
                serializedName: "adultCount",
                type: {
                    name: "Number"
                }
            },
            childCount: {
                serializedName: "childCount",
                type: {
                    name: "Number"
                }
            },
            tripDateBegin: {
                serializedName: "tripDateBegin",
                type: {
                    name: "DateTime"
                }
            },
            tripDateEnd: {
                serializedName: "tripDateEnd",
                type: {
                    name: "DateTime"
                }
            },
            claimsBookingInProgressCount: {
                serializedName: "claimsBookingInProgressCount",
                type: {
                    name: "Number"
                }
            },
            claimsBookedCount: {
                serializedName: "claimsBookedCount",
                type: {
                    name: "Number"
                }
            },
            claimsConfirmedCount: {
                serializedName: "claimsConfirmedCount",
                type: {
                    name: "Number"
                }
            },
            claimsRebookingCount: {
                serializedName: "claimsRebookingCount",
                type: {
                    name: "Number"
                }
            },
            claimsCancellationRequestedCount: {
                serializedName: "claimsCancellationRequestedCount",
                type: {
                    name: "Number"
                }
            },
            claimsCanceledWithFinesCount: {
                serializedName: "claimsCanceledWithFinesCount",
                type: {
                    name: "Number"
                }
            },
            isDeleted: {
                serializedName: "isDeleted",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const MemberStatisticsItemDtoPaginateApiResponse = {
    type: {
        name: "Composite",
        className: "MemberStatisticsItemDtoPaginateApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "MemberStatisticsItemDtoPaginate"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const MemberStatisticsItemDtoPaginate = {
    type: {
        name: "Composite",
        className: "MemberStatisticsItemDtoPaginate",
        modelProperties: {
            data: {
                serializedName: "data",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MemberStatisticsItemDto"
                        }
                    }
                }
            },
            pageSize: {
                serializedName: "pageSize",
                type: {
                    name: "Number"
                }
            },
            page: {
                serializedName: "page",
                type: {
                    name: "Number"
                }
            },
            count: {
                serializedName: "count",
                type: {
                    name: "Number"
                }
            },
            optionalSummaries: {
                serializedName: "optionalSummaries",
                nullable: true,
                type: {
                    name: "Dictionary",
                    value: { type: { name: "String" } }
                }
            }
        }
    }
};
export const MemberStatisticsItemDto = {
    type: {
        name: "Composite",
        className: "MemberStatisticsItemDto",
        modelProperties: {
            memberAccessId: {
                serializedName: "memberAccessId",
                type: {
                    name: "Number"
                }
            },
            memberFullName: {
                serializedName: "memberFullName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            tourName: {
                serializedName: "tourName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            userStartCity: {
                serializedName: "userStartCity",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            programStartCity: {
                serializedName: "programStartCity",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberIsAbsentStatus: {
                serializedName: "memberIsAbsentStatus",
                type: {
                    name: "Boolean"
                }
            },
            totalAmount: {
                serializedName: "totalAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            ageCategory: {
                serializedName: "ageCategory",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryNumber: {
                serializedName: "registryNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            candidateMemberStatus: {
                serializedName: "candidateMemberStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            phone: {
                serializedName: "phone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            city: {
                serializedName: "city",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            certificateInitialAmount: {
                serializedName: "certificateInitialAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            externalTourId: {
                serializedName: "externalTourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourGroupId: {
                serializedName: "tourGroupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            externalTourGroupId: {
                serializedName: "externalTourGroupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tripDateBegin: {
                serializedName: "tripDateBegin",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            tripDateEnd: {
                serializedName: "tripDateEnd",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            projectName: {
                serializedName: "projectName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourRegion: {
                serializedName: "tourRegion",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourType: {
                serializedName: "tourType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                type: {
                    name: "String"
                }
            },
            claimStatusName: {
                serializedName: "claimStatusName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isAutoLoad: {
                serializedName: "isAutoLoad",
                type: {
                    name: "Boolean"
                }
            },
            isSpecialProject: {
                serializedName: "isSpecialProject",
                type: {
                    name: "Boolean"
                }
            },
            memberBirthDate: {
                serializedName: "memberBirthDate",
                type: {
                    name: "DateTime"
                }
            },
            tourCategory: {
                serializedName: "tourCategory",
                type: {
                    name: "Number"
                }
            },
            age: {
                serializedName: "age",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const MembersStatisticsItemICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "MembersStatisticsItemICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MembersStatisticsItem"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const MembersStatisticsItem = {
    type: {
        name: "Composite",
        className: "MembersStatisticsItem",
        modelProperties: {
            memberStatKey: {
                serializedName: "memberStatKey",
                readOnly: true,
                type: {
                    name: "Uuid"
                }
            },
            sletatUserId: {
                serializedName: "sletatUserId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fullName: {
                serializedName: "fullName",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            },
            candidateMemberStatus: {
                serializedName: "candidateMemberStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            phone: {
                serializedName: "phone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            city: {
                serializedName: "city",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            externalTourId: {
                serializedName: "externalTourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            externalTourGroupId: {
                serializedName: "externalTourGroupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourId: {
                serializedName: "tourId",
                type: {
                    name: "Uuid"
                }
            },
            tourGroupId: {
                serializedName: "tourGroupId",
                type: {
                    name: "Uuid"
                }
            },
            tripDateBegin: {
                serializedName: "tripDateBegin",
                type: {
                    name: "DateTime"
                }
            },
            tripDateEnd: {
                serializedName: "tripDateEnd",
                type: {
                    name: "DateTime"
                }
            },
            projectName: {
                serializedName: "projectName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourRegion: {
                serializedName: "tourRegion",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourType: {
                serializedName: "tourType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            certificateInitialAmount: {
                serializedName: "certificateInitialAmount",
                type: {
                    name: "Number"
                }
            },
            claim: {
                serializedName: "claim",
                type: {
                    name: "Composite",
                    className: "ClaimDto"
                }
            }
        }
    }
};
export const InfoMessageContract = {
    type: {
        name: "Composite",
        className: "InfoMessageContract",
        modelProperties: {
            organizationType: {
                serializedName: "organizationType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            personStatus: {
                serializedName: "personStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            organizationName: {
                serializedName: "organizationName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bossRole: {
                serializedName: "bossRole",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bossFirstName: {
                serializedName: "bossFirstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bossLastName: {
                serializedName: "bossLastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bossMiddleName: {
                serializedName: "bossMiddleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            sex: {
                serializedName: "sex",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            groupName: {
                serializedName: "groupName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            className: {
                serializedName: "className",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            organizationEmail: {
                serializedName: "organizationEmail",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ObjectApiResponse = {
    type: {
        name: "Composite",
        className: "ObjectApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "any"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const InfoMessageContractApiResponse = {
    type: {
        name: "Composite",
        className: "InfoMessageContractApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "InfoMessageContract"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const InfoMessageRejectDto = {
    type: {
        name: "Composite",
        className: "InfoMessageRejectDto",
        modelProperties: {
            infoMessageRequestId: {
                serializedName: "infoMessageRequestId",
                type: {
                    name: "Number"
                }
            },
            rejectionComment: {
                serializedName: "rejectionComment",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const InfoMessageDtoPaginateApiResponse = {
    type: {
        name: "Composite",
        className: "InfoMessageDtoPaginateApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "InfoMessageDtoPaginate"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const InfoMessageDtoPaginate = {
    type: {
        name: "Composite",
        className: "InfoMessageDtoPaginate",
        modelProperties: {
            data: {
                serializedName: "data",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "InfoMessageDto"
                        }
                    }
                }
            },
            pageSize: {
                serializedName: "pageSize",
                type: {
                    name: "Number"
                }
            },
            page: {
                serializedName: "page",
                type: {
                    name: "Number"
                }
            },
            count: {
                serializedName: "count",
                type: {
                    name: "Number"
                }
            },
            optionalSummaries: {
                serializedName: "optionalSummaries",
                nullable: true,
                type: {
                    name: "Dictionary",
                    value: { type: { name: "String" } }
                }
            }
        }
    }
};
export const InfoMessageDto = {
    type: {
        name: "Composite",
        className: "InfoMessageDto",
        modelProperties: {
            infoMessageRequestId: {
                serializedName: "infoMessageRequestId",
                type: {
                    name: "Number"
                }
            },
            organizationType: {
                serializedName: "organizationType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            dateBegin: {
                serializedName: "dateBegin",
                type: {
                    name: "DateTime"
                }
            },
            dateEnd: {
                serializedName: "dateEnd",
                type: {
                    name: "DateTime"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            status: {
                serializedName: "status",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusId: {
                serializedName: "statusId",
                type: {
                    name: "Number"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            personStatus: {
                serializedName: "personStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberFullName: {
                serializedName: "memberFullName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            organizationName: {
                serializedName: "organizationName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bossRole: {
                serializedName: "bossRole",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bossFullName: {
                serializedName: "bossFullName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bossName: {
                serializedName: "bossName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bossSecondName: {
                serializedName: "bossSecondName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bossPatronymic: {
                serializedName: "bossPatronymic",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            sex: {
                serializedName: "sex",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            groupName: {
                serializedName: "groupName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            className: {
                serializedName: "className",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            organizationEmail: {
                serializedName: "organizationEmail",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isApproved: {
                serializedName: "isApproved",
                type: {
                    name: "Boolean"
                }
            },
            isRejected: {
                serializedName: "isRejected",
                type: {
                    name: "Boolean"
                }
            },
            resendOptionActive: {
                serializedName: "resendOptionActive",
                type: {
                    name: "Boolean"
                }
            },
            rejectionComment: {
                serializedName: "rejectionComment",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isAutoLoad: {
                serializedName: "isAutoLoad",
                nullable: true,
                type: {
                    name: "Boolean"
                }
            },
            isSpecialProject: {
                serializedName: "isSpecialProject",
                nullable: true,
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const InfoMessageStatusDto = {
    type: {
        name: "Composite",
        className: "InfoMessageStatusDto",
        modelProperties: {
            infoMessageRequestStatusId: {
                serializedName: "infoMessageRequestStatusId",
                type: {
                    name: "Number"
                }
            },
            rejectionComment: {
                serializedName: "rejectionComment",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const DirectumInfoMessageRequestApiModel = {
    type: {
        name: "Composite",
        className: "DirectumInfoMessageRequestApiModel",
        modelProperties: {
            infoMessageRequestId: {
                serializedName: "infoMessageRequestId",
                type: {
                    name: "Number"
                }
            },
            orgName: {
                serializedName: "orgName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            orgHeadJobTitle: {
                serializedName: "orgHeadJobTitle",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            orgEmail: {
                serializedName: "orgEmail",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            travelDates: {
                serializedName: "travelDates",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            travelDateFrom: {
                serializedName: "travelDateFrom",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            travelDateTo: {
                serializedName: "travelDateTo",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            travelCity: {
                serializedName: "travelCity",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bchpOperatorGuid: {
                serializedName: "bchpOperatorGuid",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            gender: {
                serializedName: "gender",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            candidateData: {
                serializedName: "candidateData",
                type: {
                    name: "Composite",
                    className: "DirectumCandidateDataInfoMessageRequestApiModel"
                }
            }
        }
    }
};
export const DirectumCandidateDataInfoMessageRequestApiModel = {
    type: {
        name: "Composite",
        className: "DirectumCandidateDataInfoMessageRequestApiModel",
        modelProperties: {
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            studentGroup: {
                serializedName: "studentGroup",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            candidateJobTitle: {
                serializedName: "candidateJobTitle",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            phone: {
                serializedName: "phone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            guid: {
                serializedName: "guid",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const DirectumInfoMessageRequestStatusApiModel = {
    type: {
        name: "Composite",
        className: "DirectumInfoMessageRequestStatusApiModel",
        modelProperties: {
            statusId: {
                serializedName: "statusId",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const SetInfoMessageRequestStatusApiModel = {
    type: {
        name: "Composite",
        className: "SetInfoMessageRequestStatusApiModel",
        modelProperties: {
            infoMessageRequestId: {
                serializedName: "infoMessageRequestId",
                type: {
                    name: "Number"
                }
            },
            status: {
                serializedName: "status",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const File = {
    type: {
        name: "Composite",
        className: "File",
        modelProperties: {
            fileId: {
                serializedName: "fileId",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fileSizeInBytes: {
                serializedName: "fileSizeInBytes",
                type: {
                    name: "Number"
                }
            },
            serverPath: {
                serializedName: "serverPath",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ExpiringMemberAccessListApiResponse = {
    type: {
        name: "Composite",
        className: "ExpiringMemberAccessListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ExpiringMemberAccess"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ExpiringMemberAccess = {
    type: {
        name: "Composite",
        className: "ExpiringMemberAccess",
        modelProperties: {
            sletatUserId: {
                serializedName: "sletatUserId",
                type: {
                    name: "Uuid"
                }
            },
            expireDateTime: {
                serializedName: "expireDateTime",
                type: {
                    name: "DateTime"
                }
            },
            certificateType: {
                serializedName: "certificateType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            accessId: {
                serializedName: "accessId",
                type: {
                    name: "Uuid"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            programName: {
                serializedName: "programName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            allowSelfExtend: {
                serializedName: "allowSelfExtend",
                type: {
                    name: "Boolean"
                }
            },
            isAutoBooked: {
                serializedName: "isAutoBooked",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const ParticipationCertificateCreationDto = {
    type: {
        name: "Composite",
        className: "ParticipationCertificateCreationDto",
        modelProperties: {
            sletatUserId: {
                serializedName: "sletatUserId",
                type: {
                    name: "Uuid"
                }
            },
            participationCertificateId: {
                serializedName: "participationCertificateId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourGroupId: {
                serializedName: "tourGroupId",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ParticipationCertificateDtoPaginateApiResponse = {
    type: {
        name: "Composite",
        className: "ParticipationCertificateDtoPaginateApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ParticipationCertificateDtoPaginate"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ParticipationCertificateDtoPaginate = {
    type: {
        name: "Composite",
        className: "ParticipationCertificateDtoPaginate",
        modelProperties: {
            data: {
                serializedName: "data",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ParticipationCertificateDto"
                        }
                    }
                }
            },
            pageSize: {
                serializedName: "pageSize",
                type: {
                    name: "Number"
                }
            },
            page: {
                serializedName: "page",
                type: {
                    name: "Number"
                }
            },
            count: {
                serializedName: "count",
                type: {
                    name: "Number"
                }
            },
            optionalSummaries: {
                serializedName: "optionalSummaries",
                nullable: true,
                type: {
                    name: "Dictionary",
                    value: { type: { name: "String" } }
                }
            }
        }
    }
};
export const ParticipationCertificateDto = {
    type: {
        name: "Composite",
        className: "ParticipationCertificateDto",
        modelProperties: {
            tourEndDate: {
                serializedName: "tourEndDate",
                type: {
                    name: "DateTime"
                }
            },
            certificateNumber: {
                serializedName: "certificateNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberFullName: {
                serializedName: "memberFullName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourName: {
                serializedName: "tourName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourDate: {
                serializedName: "tourDate",
                type: {
                    name: "DateTime"
                }
            },
            projectName: {
                serializedName: "projectName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const MemberAccessDtoApiResponse = {
    type: {
        name: "Composite",
        className: "MemberAccessDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "MemberAccessDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const MemberAccessExtendRequest = {
    type: {
        name: "Composite",
        className: "MemberAccessExtendRequest",
        modelProperties: {
            memberAccessId: {
                serializedName: "memberAccessId",
                type: {
                    name: "Number"
                }
            },
            expireDateTime: {
                serializedName: "expireDateTime",
                type: {
                    name: "DateTime"
                }
            },
            extendReason: {
                serializedName: "extendReason",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const MemberAccessBlockRequest = {
    type: {
        name: "Composite",
        className: "MemberAccessBlockRequest",
        modelProperties: {
            memberAccessId: {
                serializedName: "memberAccessId",
                type: {
                    name: "Number"
                }
            },
            reasonId: {
                serializedName: "reasonId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const MemberAccessCreateRequest = {
    type: {
        name: "Composite",
        className: "MemberAccessCreateRequest",
        modelProperties: {
            candidateMemberId: {
                serializedName: "candidateMemberId",
                type: {
                    name: "Number"
                }
            },
            expireDateTime: {
                serializedName: "expireDateTime",
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const MemberAccessInfoItemDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "MemberAccessInfoItemDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MemberAccessInfoItemDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const MemberAccessInfoItemDto = {
    type: {
        name: "Composite",
        className: "MemberAccessInfoItemDto",
        modelProperties: {
            memberAccessId: {
                serializedName: "memberAccessId",
                type: {
                    name: "Number"
                }
            },
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            currentClaimNumber: {
                serializedName: "currentClaimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            currentClaimStatus: {
                serializedName: "currentClaimStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            currentClaimAmount: {
                serializedName: "currentClaimAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            currentCertificate: {
                serializedName: "currentCertificate",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            certificateAmount: {
                serializedName: "certificateAmount",
                type: {
                    name: "Number"
                }
            },
            accessExpireDate: {
                serializedName: "accessExpireDate",
                type: {
                    name: "DateTime"
                }
            },
            isExpired: {
                serializedName: "isExpired",
                type: {
                    name: "Boolean"
                }
            },
            totalDays: {
                serializedName: "totalDays",
                type: {
                    name: "Number"
                }
            },
            leftDays: {
                serializedName: "leftDays",
                type: {
                    name: "Number"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            isAutoLoad: {
                serializedName: "isAutoLoad",
                type: {
                    name: "Boolean"
                }
            },
            isSpecialProject: {
                serializedName: "isSpecialProject",
                type: {
                    name: "Boolean"
                }
            },
            candidateMemberListId: {
                serializedName: "candidateMemberListId",
                type: {
                    name: "Number"
                }
            },
            tourCategory: {
                serializedName: "tourCategory",
                type: {
                    name: "Number"
                }
            },
            groupId: {
                serializedName: "groupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            accessIsBlocked: {
                serializedName: "accessIsBlocked",
                type: {
                    name: "Boolean"
                }
            },
            memberEmail: {
                serializedName: "memberEmail",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberFirstName: {
                serializedName: "memberFirstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberLastName: {
                serializedName: "memberLastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberFullName: {
                serializedName: "memberFullName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberBirthDate: {
                serializedName: "memberBirthDate",
                type: {
                    name: "DateTime"
                }
            },
            city: {
                serializedName: "city",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const MemberAccessInfoItemDtoPaginateApiResponse = {
    type: {
        name: "Composite",
        className: "MemberAccessInfoItemDtoPaginateApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "MemberAccessInfoItemDtoPaginate"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const MemberAccessInfoItemDtoPaginate = {
    type: {
        name: "Composite",
        className: "MemberAccessInfoItemDtoPaginate",
        modelProperties: {
            data: {
                serializedName: "data",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MemberAccessInfoItemDto"
                        }
                    }
                }
            },
            pageSize: {
                serializedName: "pageSize",
                type: {
                    name: "Number"
                }
            },
            page: {
                serializedName: "page",
                type: {
                    name: "Number"
                }
            },
            count: {
                serializedName: "count",
                type: {
                    name: "Number"
                }
            },
            optionalSummaries: {
                serializedName: "optionalSummaries",
                nullable: true,
                type: {
                    name: "Dictionary",
                    value: { type: { name: "String" } }
                }
            }
        }
    }
};
export const MemberICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "MemberICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "Member"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const MemberPaginateApiResponse = {
    type: {
        name: "Composite",
        className: "MemberPaginateApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "MemberPaginate"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const MemberPaginate = {
    type: {
        name: "Composite",
        className: "MemberPaginate",
        modelProperties: {
            data: {
                serializedName: "data",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "Member"
                        }
                    }
                }
            },
            pageSize: {
                serializedName: "pageSize",
                type: {
                    name: "Number"
                }
            },
            page: {
                serializedName: "page",
                type: {
                    name: "Number"
                }
            },
            count: {
                serializedName: "count",
                type: {
                    name: "Number"
                }
            },
            optionalSummaries: {
                serializedName: "optionalSummaries",
                nullable: true,
                type: {
                    name: "Dictionary",
                    value: { type: { name: "String" } }
                }
            }
        }
    }
};
export const InvitedMemberDtoPaginateApiResponse = {
    type: {
        name: "Composite",
        className: "InvitedMemberDtoPaginateApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "InvitedMemberDtoPaginate"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const InvitedMemberDtoPaginate = {
    type: {
        name: "Composite",
        className: "InvitedMemberDtoPaginate",
        modelProperties: {
            data: {
                serializedName: "data",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "InvitedMemberDto"
                        }
                    }
                }
            },
            pageSize: {
                serializedName: "pageSize",
                type: {
                    name: "Number"
                }
            },
            page: {
                serializedName: "page",
                type: {
                    name: "Number"
                }
            },
            count: {
                serializedName: "count",
                type: {
                    name: "Number"
                }
            },
            optionalSummaries: {
                serializedName: "optionalSummaries",
                nullable: true,
                type: {
                    name: "Dictionary",
                    value: { type: { name: "String" } }
                }
            }
        }
    }
};
export const InvitedMemberDto = {
    type: {
        name: "Composite",
        className: "InvitedMemberDto",
        modelProperties: {
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            memberFullName: {
                serializedName: "memberFullName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isInvited: {
                serializedName: "isInvited",
                type: {
                    name: "Boolean"
                }
            },
            competition: {
                serializedName: "competition",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            activateDateTime: {
                serializedName: "activateDateTime",
                type: {
                    name: "DateTime"
                }
            },
            personalAccountRegistered: {
                serializedName: "personalAccountRegistered",
                type: {
                    name: "Boolean"
                }
            },
            personalAccountRegistrationDate: {
                serializedName: "personalAccountRegistrationDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const MemberApiResponse = {
    type: {
        name: "Composite",
        className: "MemberApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "Member"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const MemberCardDtoApiResponse = {
    type: {
        name: "Composite",
        className: "MemberCardDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "MemberCardDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const MemberCardDto = {
    type: {
        name: "Composite",
        className: "MemberCardDto",
        modelProperties: {
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            activateDateTime: {
                serializedName: "activateDateTime",
                type: {
                    name: "DateTime"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isBlocked: {
                serializedName: "isBlocked",
                type: {
                    name: "Boolean"
                }
            },
            claims: {
                serializedName: "claims",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MemberAccessClaim"
                        }
                    }
                }
            },
            memberAccesses: {
                serializedName: "memberAccesses",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MemberAccess"
                        }
                    }
                }
            },
            memberFullName: {
                serializedName: "memberFullName",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            contactPhone: {
                serializedName: "contactPhone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            birthDate: {
                serializedName: "birthDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            candidateMembers: {
                serializedName: "candidateMembers",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "Candidate"
                        }
                    }
                }
            },
            candidateInListCount: {
                serializedName: "candidateInListCount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            memberAccessActiveCount: {
                serializedName: "memberAccessActiveCount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            claimCount: {
                serializedName: "claimCount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            changeRequests: {
                serializedName: "changeRequests",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChangeMemberDataResponseDto"
                        }
                    }
                }
            },
            competition: {
                serializedName: "competition",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            sletatUserId: {
                serializedName: "sletatUserId",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ChangeMemberDataResponseDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "ChangeMemberDataResponseDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChangeMemberDataResponseDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ChangeMemberDataRequestDto = {
    type: {
        name: "Composite",
        className: "ChangeMemberDataRequestDto",
        modelProperties: {
            memberId: {
                serializedName: "memberId",
                required: true,
                type: {
                    name: "Number"
                }
            },
            firstName: {
                constraints: {
                    MinLength: 1
                },
                serializedName: "firstName",
                required: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                constraints: {
                    MinLength: 1
                },
                serializedName: "middleName",
                required: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                constraints: {
                    MinLength: 1
                },
                serializedName: "lastName",
                required: true,
                type: {
                    name: "String"
                }
            },
            contactPhone: {
                constraints: {
                    MinLength: 1
                },
                serializedName: "contactPhone",
                required: true,
                type: {
                    name: "String"
                }
            },
            birthDate: {
                serializedName: "birthDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const ChangeMemberDataResponseDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ChangeMemberDataResponseDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ChangeMemberDataResponseDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const FastLoginLinkResponseApiResponse = {
    type: {
        name: "Composite",
        className: "FastLoginLinkResponseApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "FastLoginLinkResponse"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const FastLoginLinkResponse = {
    type: {
        name: "Composite",
        className: "FastLoginLinkResponse",
        modelProperties: {
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            link: {
                serializedName: "link",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const MemberPasswordResultResponseDtoApiResponse = {
    type: {
        name: "Composite",
        className: "MemberPasswordResultResponseDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "MemberPasswordResultResponseDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const MemberPasswordResultResponseDto = {
    type: {
        name: "Composite",
        className: "MemberPasswordResultResponseDto",
        modelProperties: {
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            passwordIsSet: {
                serializedName: "passwordIsSet",
                nullable: true,
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const ChangeMemberEmailRequestDto = {
    type: {
        name: "Composite",
        className: "ChangeMemberEmailRequestDto",
        modelProperties: {
            newEmail: {
                constraints: {
                    MinLength: 1
                },
                serializedName: "newEmail",
                required: true,
                type: {
                    name: "String"
                }
            },
            needSendEmail: {
                serializedName: "needSendEmail",
                required: true,
                type: {
                    name: "Boolean"
                }
            },
            programName: {
                constraints: {
                    MinLength: 1
                },
                serializedName: "programName",
                required: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ChangeMemberEmailResultResponseDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ChangeMemberEmailResultResponseDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ChangeMemberEmailResultResponseDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ChangeMemberEmailResultResponseDto = {
    type: {
        name: "Composite",
        className: "ChangeMemberEmailResultResponseDto",
        modelProperties: {
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            emailChanged: {
                serializedName: "emailChanged",
                nullable: true,
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const ParameterizedQuery = {
    type: {
        name: "Composite",
        className: "ParameterizedQuery",
        modelProperties: {
            dataFilters: {
                serializedName: "dataFilters",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FilterItem"
                        }
                    }
                }
            },
            orderBy: {
                serializedName: "orderBy",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            sortOrder: {
                serializedName: "sortOrder",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            pageNumber: {
                serializedName: "pageNumber",
                type: {
                    name: "Number"
                }
            },
            pageSize: {
                serializedName: "pageSize",
                type: {
                    name: "Number"
                }
            },
            searchString: {
                serializedName: "searchString",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const FilterItem = {
    type: {
        name: "Composite",
        className: "FilterItem",
        modelProperties: {
            fieldName: {
                serializedName: "fieldName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            operator: {
                serializedName: "operator",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fieldValue: {
                serializedName: "fieldValue",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const TableRowPaginateApiResponse = {
    type: {
        name: "Composite",
        className: "TableRowPaginateApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "TableRowPaginate"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const TableRowPaginate = {
    type: {
        name: "Composite",
        className: "TableRowPaginate",
        modelProperties: {
            data: {
                serializedName: "data",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "TableRow"
                        }
                    }
                }
            },
            pageSize: {
                serializedName: "pageSize",
                type: {
                    name: "Number"
                }
            },
            page: {
                serializedName: "page",
                type: {
                    name: "Number"
                }
            },
            count: {
                serializedName: "count",
                type: {
                    name: "Number"
                }
            },
            optionalSummaries: {
                serializedName: "optionalSummaries",
                nullable: true,
                type: {
                    name: "Dictionary",
                    value: { type: { name: "String" } }
                }
            }
        }
    }
};
export const TableRow = {
    type: {
        name: "Composite",
        className: "TableRow",
        modelProperties: {
            columns: {
                serializedName: "columns",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "TableColumn"
                        }
                    }
                }
            }
        }
    }
};
export const TableColumn = {
    type: {
        name: "Composite",
        className: "TableColumn",
        modelProperties: {
            title: {
                serializedName: "title",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            field: {
                serializedName: "field",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isKey: {
                serializedName: "isKey",
                type: {
                    name: "Boolean"
                }
            },
            isSelector: {
                serializedName: "isSelector",
                type: {
                    name: "Boolean"
                }
            },
            columnValue: {
                serializedName: "columnValue",
                nullable: true,
                type: {
                    name: "any"
                }
            },
            options: {
                serializedName: "options",
                nullable: true,
                type: {
                    name: "any"
                }
            },
            optionsTyped: {
                serializedName: "optionsTyped",
                type: {
                    name: "Composite",
                    className: "MixedOptions"
                }
            }
        }
    }
};
export const MixedOptions = {
    type: {
        name: "Composite",
        className: "MixedOptions",
        modelProperties: {
            type: {
                serializedName: "type",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ExportOptions = {
    type: {
        name: "Composite",
        className: "ExportOptions",
        modelProperties: {
            dataFilters: {
                serializedName: "dataFilters",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FilterItem"
                        }
                    }
                }
            },
            columns: {
                serializedName: "columns",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            }
        }
    }
};
export const StringListApiResponse = {
    type: {
        name: "Composite",
        className: "StringListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const TestimonialPropertyApiResponse = {
    type: {
        name: "Composite",
        className: "TestimonialPropertyApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "TestimonialProperty"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const TestimonialProperty = {
    type: {
        name: "Composite",
        className: "TestimonialProperty",
        modelProperties: {
            types: {
                serializedName: "types",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            parties: {
                serializedName: "parties",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            topics: {
                serializedName: "topics",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            subtopics: {
                serializedName: "subtopics",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            publicId: {
                serializedName: "publicId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            code: {
                serializedName: "code",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            objectRef: {
                serializedName: "objectRef",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const UpdateTestimonialDto = {
    type: {
        name: "Composite",
        className: "UpdateTestimonialDto",
        modelProperties: {
            claimNumber: {
                constraints: {
                    MinLength: 1
                },
                serializedName: "claimNumber",
                required: true,
                type: {
                    name: "String"
                }
            },
            feedbackType: {
                serializedName: "feedbackType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            party: {
                serializedName: "party",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            topic: {
                serializedName: "topic",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            subtopic: {
                serializedName: "subtopic",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const TableRowApiResponse = {
    type: {
        name: "Composite",
        className: "TableRowApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "TableRow"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const DateTimeListApiResponse = {
    type: {
        name: "Composite",
        className: "DateTimeListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "DateTime"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ProviderDtoListApiResponse = {
    type: {
        name: "Composite",
        className: "ProviderDtoListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProviderDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ProviderDto = {
    type: {
        name: "Composite",
        className: "ProviderDto",
        modelProperties: {
            providerId: {
                serializedName: "providerId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            sletatId: {
                serializedName: "sletatId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            providerToBudgetAccesses: {
                serializedName: "providerToBudgetAccesses",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProviderToBudgetDto"
                        }
                    }
                }
            }
        }
    }
};
export const ProviderToBudgetDto = {
    type: {
        name: "Composite",
        className: "ProviderToBudgetDto",
        modelProperties: {
            providerToBudgetAccessId: {
                serializedName: "providerToBudgetAccessId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            providerId: {
                serializedName: "providerId",
                type: {
                    name: "Number"
                }
            },
            budgetId: {
                serializedName: "budgetId",
                type: {
                    name: "Number"
                }
            },
            approved: {
                serializedName: "approved",
                type: {
                    name: "Boolean"
                }
            },
            disabled: {
                serializedName: "disabled",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const AgentReportDtoListApiResponse = {
    type: {
        name: "Composite",
        className: "AgentReportDtoListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "AgentReportDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const AgentReportDto = {
    type: {
        name: "Composite",
        className: "AgentReportDto",
        modelProperties: {
            agentReportId: {
                serializedName: "agentReportId",
                type: {
                    name: "Uuid"
                }
            },
            datePeriod: {
                serializedName: "datePeriod",
                type: {
                    name: "DateTime"
                }
            },
            providerId: {
                serializedName: "providerId",
                type: {
                    name: "Number"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isSent: {
                serializedName: "isSent",
                type: {
                    name: "Boolean"
                }
            },
            isSigned: {
                serializedName: "isSigned",
                type: {
                    name: "Boolean"
                }
            },
            isEmailSent: {
                serializedName: "isEmailSent",
                type: {
                    name: "Boolean"
                }
            },
            reservationDeptStatusId: {
                serializedName: "reservationDeptStatusId",
                type: {
                    name: "Number"
                }
            },
            createdOnly: {
                serializedName: "createdOnly",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const AgentReportCreateDto = {
    type: {
        name: "Composite",
        className: "AgentReportCreateDto",
        modelProperties: {
            agentReportId: {
                serializedName: "agentReportId",
                type: {
                    name: "Uuid"
                }
            },
            providerId: {
                serializedName: "providerId",
                type: {
                    name: "Number"
                }
            },
            reservationDeptStatusId: {
                serializedName: "reservationDeptStatusId",
                type: {
                    name: "Number"
                }
            },
            createOnly: {
                serializedName: "createOnly",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const AgentReportDtoApiResponse = {
    type: {
        name: "Composite",
        className: "AgentReportDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "AgentReportDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ReservationDeptStatusDtoListApiResponse = {
    type: {
        name: "Composite",
        className: "ReservationDeptStatusDtoListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ReservationDeptStatusDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ReservationDeptStatusDto = {
    type: {
        name: "Composite",
        className: "ReservationDeptStatusDto",
        modelProperties: {
            reservationDeptStatusId: {
                serializedName: "reservationDeptStatusId",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const AgentReportUpdateDto = {
    type: {
        name: "Composite",
        className: "AgentReportUpdateDto",
        modelProperties: {
            reservationDeptStatus: {
                serializedName: "reservationDeptStatus",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const InformationMessageInputApiResponse = {
    type: {
        name: "Composite",
        className: "InformationMessageInputApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "InformationMessageInput"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const InformationMessageInput = {
    type: {
        name: "Composite",
        className: "InformationMessageInput",
        modelProperties: {
            infoMessageRequest: {
                serializedName: "infoMessageRequest",
                type: {
                    name: "Composite",
                    className: "InfoMessageRequestDto"
                }
            },
            infoNoticeStaticFields: {
                serializedName: "infoNoticeStaticFields",
                type: {
                    name: "Composite",
                    className: "InfoNoticeStaticFieldsDto"
                }
            },
            artifact: {
                serializedName: "artifact",
                type: {
                    name: "Composite",
                    className: "ArtifactDto"
                }
            },
            isArtifact: {
                serializedName: "isArtifact",
                type: {
                    name: "Boolean"
                }
            },
            generatedParagraph: {
                serializedName: "generatedParagraph",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const InfoMessageRequestDto = {
    type: {
        name: "Composite",
        className: "InfoMessageRequestDto",
        modelProperties: {
            infoMessageRequestId: {
                serializedName: "infoMessageRequestId",
                type: {
                    name: "Number"
                }
            },
            infoMessageRequestStatus: {
                serializedName: "infoMessageRequestStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberTourGroupDateFrom: {
                serializedName: "MemberTourGroupDateFrom",
                type: {
                    name: "DateTime"
                }
            },
            memberTourGroupDateTo: {
                serializedName: "MemberTourGroupDateTo",
                type: {
                    name: "DateTime"
                }
            },
            memberTourGroupDates: {
                serializedName: "memberTourGroupDates",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberTourDestination: {
                serializedName: "memberTourDestination",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberClaimNumber: {
                serializedName: "memberClaimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberEmail: {
                serializedName: "memberEmail",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberPosition: {
                serializedName: "memberPosition",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberLastname: {
                serializedName: "memberLastname",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberFirstName: {
                serializedName: "memberFirstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberMiddleName: {
                serializedName: "memberMiddleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberFullName: {
                serializedName: "memberFullName",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberGender: {
                serializedName: "memberGender",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberOrganizationGroupName: {
                serializedName: "memberOrganizationGroupName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberOrganizationGroupUnit: {
                serializedName: "memberOrganizationGroupUnit",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            organizationType: {
                serializedName: "organizationType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            organizationName: {
                serializedName: "organizationName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            organizationEmail: {
                serializedName: "organizationEmail",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            organizationHeadPosition: {
                serializedName: "organizationHeadPosition",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            organizationHeadLastname: {
                serializedName: "organizationHeadLastname",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            organizationHeadFirstName: {
                serializedName: "organizationHeadFirstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            organizationHeadMiddleName: {
                serializedName: "organizationHeadMiddleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            organizationHeadGender: {
                serializedName: "organizationHeadGender",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            organizationHeadFullNameShort: {
                serializedName: "organizationHeadFullNameShort",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const InfoNoticeStaticFieldsDto = {
    type: {
        name: "Composite",
        className: "InfoNoticeStaticFieldsDto",
        modelProperties: {
            nowDate: {
                serializedName: "nowDate",
                type: {
                    name: "DateTime"
                }
            },
            compellation: {
                serializedName: "compellation",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            competitionState: {
                serializedName: "competitionState",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            operatorFullName: {
                serializedName: "operatorFullName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            operatorJobTitle: {
                serializedName: "operatorJobTitle",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            operatorEmail: {
                serializedName: "operatorEmail",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            textChunk1: {
                serializedName: "textChunk1",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            textChunk2: {
                serializedName: "textChunk2",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            textChunk3: {
                serializedName: "textChunk3",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            paragraphs: {
                serializedName: "paragraphs",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            textChunk4: {
                serializedName: "textChunk4",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            textChunk5: {
                serializedName: "textChunk5",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            textChunk6: {
                serializedName: "textChunk6",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            includeGeneratedParagraph: {
                serializedName: "includeGeneratedParagraph",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const ArtifactDto = {
    type: {
        name: "Composite",
        className: "ArtifactDto",
        modelProperties: {
            fileName: {
                serializedName: "fileName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ProgramDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "ProgramDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProgramDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ProgramDto = {
    type: {
        name: "Composite",
        className: "ProgramDto",
        modelProperties: {
            programId: {
                serializedName: "programId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ProgramDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ProgramDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ProgramDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const UserToBudgetByProviderDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "UserToBudgetByProviderDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "UserToBudgetByProviderDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const UserToBudgetByProviderDto = {
    type: {
        name: "Composite",
        className: "UserToBudgetByProviderDto",
        modelProperties: {
            programId: {
                serializedName: "programId",
                type: {
                    name: "Number"
                }
            },
            budgetId: {
                serializedName: "budgetId",
                type: {
                    name: "Number"
                }
            },
            programName: {
                serializedName: "programName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            budgetName: {
                serializedName: "budgetName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            providerId: {
                serializedName: "providerId",
                type: {
                    name: "Number"
                }
            },
            providerToBudgetAccessId: {
                serializedName: "providerToBudgetAccessId",
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const BudgetsByProviderDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "BudgetsByProviderDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "BudgetsByProviderDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const BudgetsByProviderDto = {
    type: {
        name: "Composite",
        className: "BudgetsByProviderDto",
        modelProperties: {
            programId: {
                serializedName: "programId",
                type: {
                    name: "Number"
                }
            },
            budgetId: {
                serializedName: "budgetId",
                type: {
                    name: "Number"
                }
            },
            programName: {
                serializedName: "programName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            budgetName: {
                serializedName: "budgetName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            disabled: {
                serializedName: "disabled",
                type: {
                    name: "Boolean"
                }
            },
            approved: {
                serializedName: "approved",
                type: {
                    name: "Boolean"
                }
            },
            providerId: {
                serializedName: "providerId",
                type: {
                    name: "Number"
                }
            },
            providerToBudgetAccessId: {
                serializedName: "providerToBudgetAccessId",
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const ProjectDtoListApiResponse = {
    type: {
        name: "Composite",
        className: "ProjectDtoListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProjectDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ProjectDto = {
    type: {
        name: "Composite",
        className: "ProjectDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            programId: {
                serializedName: "programId",
                type: {
                    name: "Number"
                }
            },
            canBeRemoved: {
                serializedName: "canBeRemoved",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const ProjectCreateRequest = {
    type: {
        name: "Composite",
        className: "ProjectCreateRequest",
        modelProperties: {
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            programId: {
                serializedName: "programId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ProjectDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ProjectDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ProjectDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ProjectUpdateRequest = {
    type: {
        name: "Composite",
        className: "ProjectUpdateRequest",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            programId: {
                serializedName: "programId",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const UserToProviderAccessDtoApiResponse = {
    type: {
        name: "Composite",
        className: "UserToProviderAccessDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "UserToProviderAccessDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const UserToProviderAccessDto = {
    type: {
        name: "Composite",
        className: "UserToProviderAccessDto",
        modelProperties: {
            userToProviderAccessId: {
                serializedName: "userToProviderAccessId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            aspNetUserId: {
                constraints: {
                    MinLength: 1
                },
                serializedName: "aspNetUserId",
                required: true,
                type: {
                    name: "String"
                }
            },
            providerId: {
                serializedName: "providerId",
                required: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const UserDTOPaginatedResultApiResponse = {
    type: {
        name: "Composite",
        className: "UserDTOPaginatedResultApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "UserDTOPaginatedResult"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const UserDTOPaginatedResult = {
    type: {
        name: "Composite",
        className: "UserDTOPaginatedResult",
        modelProperties: {
            skipped: {
                serializedName: "skipped",
                type: {
                    name: "Number"
                }
            },
            taked: {
                serializedName: "taked",
                type: {
                    name: "Number"
                }
            },
            totalCount: {
                serializedName: "totalCount",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "UserDTO"
                        }
                    }
                }
            }
        }
    }
};
export const UserDTO = {
    type: {
        name: "Composite",
        className: "UserDTO",
        modelProperties: {
            aspNetUserId: {
                serializedName: "aspNetUserId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isActive: {
                serializedName: "isActive",
                type: {
                    name: "Boolean"
                }
            },
            login: {
                serializedName: "login",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fullName: {
                serializedName: "fullName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            roles: {
                serializedName: "roles",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            }
        }
    }
};
export const UserToProviderAccessDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "UserToProviderAccessDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "UserToProviderAccessDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const UserWithProviderAccessDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "UserWithProviderAccessDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "UserWithProviderAccessDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const UserWithProviderAccessDto = {
    type: {
        name: "Composite",
        className: "UserWithProviderAccessDto",
        modelProperties: {
            aspNetUserId: {
                serializedName: "aspNetUserId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            providers: {
                serializedName: "providers",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProviderDto"
                        }
                    }
                }
            },
            budgets: {
                serializedName: "budgets",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "BudgetDto"
                        }
                    }
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ProviderInfoDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ProviderInfoDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ProviderInfoDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ProviderInfoDto = {
    type: {
        name: "Composite",
        className: "ProviderInfoDto",
        modelProperties: {
            providerId: {
                serializedName: "providerId",
                type: {
                    name: "Number"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ProviderDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "ProviderDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProviderDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ProviderDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ProviderDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ProviderDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ProviderToBudgetDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ProviderToBudgetDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ProviderToBudgetDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ResultClaimDtoPaginateApiResponse = {
    type: {
        name: "Composite",
        className: "ResultClaimDtoPaginateApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ResultClaimDtoPaginate"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ResultClaimDtoPaginate = {
    type: {
        name: "Composite",
        className: "ResultClaimDtoPaginate",
        modelProperties: {
            data: {
                serializedName: "data",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ResultClaimDto"
                        }
                    }
                }
            },
            pageSize: {
                serializedName: "pageSize",
                type: {
                    name: "Number"
                }
            },
            page: {
                serializedName: "page",
                type: {
                    name: "Number"
                }
            },
            count: {
                serializedName: "count",
                type: {
                    name: "Number"
                }
            },
            optionalSummaries: {
                serializedName: "optionalSummaries",
                nullable: true,
                type: {
                    name: "Dictionary",
                    value: { type: { name: "String" } }
                }
            }
        }
    }
};
export const ResultClaimDto = {
    type: {
        name: "Composite",
        className: "ResultClaimDto",
        modelProperties: {
            bookingCenterClaimId: {
                serializedName: "bookingCenterClaimId",
                type: {
                    name: "Uuid"
                }
            },
            certificate: {
                serializedName: "certificate",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            resultClaimTourOperatorStatus: {
                serializedName: "resultClaimTourOperatorStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            resultClaimAdministratorStatus: {
                serializedName: "resultClaimAdministratorStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            touristFullName: {
                serializedName: "touristFullName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            dateBegin: {
                serializedName: "dateBegin",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            dateEnd: {
                serializedName: "dateEnd",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            groupId: {
                serializedName: "groupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourId: {
                serializedName: "tourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            budgetId: {
                serializedName: "budgetId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            budgetName: {
                serializedName: "budgetName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            bookingCenterClaimStatusName: {
                serializedName: "bookingCenterClaimStatusName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourOperatorName: {
                serializedName: "tourOperatorName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourOperatorSletatId: {
                serializedName: "tourOperatorSletatId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            bookingCenterClaimStatusId: {
                serializedName: "bookingCenterClaimStatusId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            checkStatusRef: {
                serializedName: "checkStatusRef",
                type: {
                    name: "String"
                }
            },
            checkStatusName: {
                serializedName: "checkStatusName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claimLink: {
                serializedName: "claimLink",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statFiles: {
                serializedName: "statFiles",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "StatFile"
                        }
                    }
                }
            },
            toFilesCount: {
                serializedName: "toFilesCount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            identityResponsibleUser: {
                serializedName: "identityResponsibleUser",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            responsibleUser: {
                serializedName: "responsibleUser",
                type: {
                    name: "Composite",
                    className: "UserDTO"
                }
            },
            comment: {
                serializedName: "comment",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const StatFile = {
    type: {
        name: "Composite",
        className: "StatFile",
        modelProperties: {
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            count: {
                serializedName: "count",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const StatisticsByOperatorResultClaimDtoListApiResponse = {
    type: {
        name: "Composite",
        className: "StatisticsByOperatorResultClaimDtoListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "StatisticsByOperatorResultClaimDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const StatisticsByOperatorResultClaimDto = {
    type: {
        name: "Composite",
        className: "StatisticsByOperatorResultClaimDto",
        modelProperties: {
            totalPercent: {
                serializedName: "totalPercent",
                readOnly: true,
                type: {
                    name: "Number"
                }
            },
            totalClaims: {
                serializedName: "totalClaims",
                type: {
                    name: "Number"
                }
            },
            completedClaims: {
                serializedName: "completedClaims",
                type: {
                    name: "Number"
                }
            },
            partCompletedClaims: {
                serializedName: "partCompletedClaims",
                type: {
                    name: "Number"
                }
            },
            tourOperatorName: {
                serializedName: "tourOperatorName",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourOperatorSletatId: {
                serializedName: "tourOperatorSletatId",
                readOnly: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const StatisticsResultClaimDtoApiResponse = {
    type: {
        name: "Composite",
        className: "StatisticsResultClaimDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "StatisticsResultClaimDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const StatisticsResultClaimDto = {
    type: {
        name: "Composite",
        className: "StatisticsResultClaimDto",
        modelProperties: {
            percent: {
                serializedName: "percent",
                type: {
                    name: "Number"
                }
            },
            totalClaims: {
                serializedName: "totalClaims",
                type: {
                    name: "Number"
                }
            },
            completedClaims: {
                serializedName: "completedClaims",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ResultClaimDetailedDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ResultClaimDetailedDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ResultClaimDetailedDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ResultClaimDetailedDto = {
    type: {
        name: "Composite",
        className: "ResultClaimDetailedDto",
        modelProperties: {
            bookingCenterClaimId: {
                serializedName: "bookingCenterClaimId",
                type: {
                    name: "Uuid"
                }
            },
            budgetId: {
                serializedName: "budgetId",
                type: {
                    name: "Number"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            touristFullName: {
                serializedName: "touristFullName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            certificate: {
                serializedName: "certificate",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourId: {
                serializedName: "tourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            groupId: {
                serializedName: "groupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            resultClaimTourOperatorStatusId: {
                serializedName: "resultClaimTourOperatorStatusId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            resultClaimAdministratorStatusId: {
                serializedName: "resultClaimAdministratorStatusId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            tourOperatorNumber: {
                serializedName: "tourOperatorNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claimFiles: {
                serializedName: "claimFiles",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ClaimFileDto"
                        }
                    }
                }
            },
            checkStatusRef: {
                serializedName: "checkStatusRef",
                type: {
                    name: "String"
                }
            },
            checkStatusName: {
                serializedName: "checkStatusName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            comment: {
                serializedName: "comment",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ClaimCheckStatusDtoListApiResponse = {
    type: {
        name: "Composite",
        className: "ClaimCheckStatusDtoListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ClaimCheckStatusDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimCheckStatusDto = {
    type: {
        name: "Composite",
        className: "ClaimCheckStatusDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "String"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ResultClaimFileToConvertDto = {
    type: {
        name: "Composite",
        className: "ResultClaimFileToConvertDto",
        modelProperties: {
            url: {
                serializedName: "url",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            type: {
                serializedName: "type",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ResultClaimSetResponsibleUser = {
    type: {
        name: "Composite",
        className: "ResultClaimSetResponsibleUser",
        modelProperties: {
            identityResponsibleUser: {
                serializedName: "identityResponsibleUser",
                type: {
                    name: "Uuid"
                }
            },
            claimId: {
                serializedName: "claimId",
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const ResultClaimDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ResultClaimDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ResultClaimDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ResultClaimSetComment = {
    type: {
        name: "Composite",
        className: "ResultClaimSetComment",
        modelProperties: {
            comment: {
                serializedName: "comment",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claimId: {
                serializedName: "claimId",
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const ResultGroupDtoListApiResponse = {
    type: {
        name: "Composite",
        className: "ResultGroupDtoListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ResultGroupDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ResultGroupDto = {
    type: {
        name: "Composite",
        className: "ResultGroupDto",
        modelProperties: {
            resultGroupId: {
                serializedName: "resultGroupId",
                type: {
                    name: "Uuid"
                }
            },
            regNumber: {
                serializedName: "regNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            groupId: {
                serializedName: "groupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            regSumAmount: {
                serializedName: "regSumAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            touristCount: {
                serializedName: "touristCount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            pricePerTourist: {
                serializedName: "pricePerTourist",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            placeArrival: {
                serializedName: "placeArrival",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourStartDate: {
                serializedName: "tourStartDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            tourStopDate: {
                serializedName: "tourStopDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            calculationOuterTourLogistic: {
                serializedName: "calculationOuterTourLogistic",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationInnerTourLogistic: {
                serializedName: "calculationInnerTourLogistic",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationAccommodation: {
                serializedName: "calculationAccommodation",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationMeal: {
                serializedName: "calculationMeal",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationExcursion: {
                serializedName: "calculationExcursion",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationProfitProgram: {
                serializedName: "calculationProfitProgram",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationInsurance: {
                serializedName: "calculationInsurance",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            confirmationMailLink: {
                serializedName: "confirmationMailLink",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            providerId: {
                serializedName: "providerId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ResultGroupDetailedDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ResultGroupDetailedDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ResultGroupDetailedDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ResultGroupDetailedDto = {
    type: {
        name: "Composite",
        className: "ResultGroupDetailedDto",
        modelProperties: {
            resultGroupId: {
                serializedName: "resultGroupId",
                type: {
                    name: "Uuid"
                }
            },
            regNumber: {
                serializedName: "regNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            groupId: {
                serializedName: "groupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            regSumAmount: {
                serializedName: "regSumAmount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            touristCount: {
                serializedName: "touristCount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            pricePerTourist: {
                serializedName: "pricePerTourist",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            placeArrival: {
                serializedName: "placeArrival",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourStartDate: {
                serializedName: "tourStartDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            tourStopDate: {
                serializedName: "tourStopDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            calculationOuterTourLogistic: {
                serializedName: "calculationOuterTourLogistic",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationInnerTourLogistic: {
                serializedName: "calculationInnerTourLogistic",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationAccommodation: {
                serializedName: "calculationAccommodation",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationMeal: {
                serializedName: "calculationMeal",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationExcursion: {
                serializedName: "calculationExcursion",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationProfitProgram: {
                serializedName: "calculationProfitProgram",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationInsurance: {
                serializedName: "calculationInsurance",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            confirmationMailLink: {
                serializedName: "confirmationMailLink",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourOperatorComment: {
                serializedName: "tourOperatorComment",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            providerId: {
                serializedName: "providerId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ResultGroupUpdateRequest = {
    type: {
        name: "Composite",
        className: "ResultGroupUpdateRequest",
        modelProperties: {
            resultGroupId: {
                serializedName: "resultGroupId",
                type: {
                    name: "Uuid"
                }
            },
            touristCount: {
                constraints: {
                    InclusiveMaximum: 5000,
                    InclusiveMinimum: 1
                },
                serializedName: "touristCount",
                required: true,
                type: {
                    name: "Number"
                }
            },
            pricePerTourist: {
                constraints: {
                    InclusiveMinimum: 0
                },
                serializedName: "pricePerTourist",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            placeArrival: {
                serializedName: "placeArrival",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourStartDate: {
                serializedName: "tourStartDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            tourStopDate: {
                serializedName: "tourStopDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            calculationOuterTourLogistic: {
                constraints: {
                    InclusiveMinimum: 0
                },
                serializedName: "calculationOuterTourLogistic",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationInnerTourLogistic: {
                constraints: {
                    InclusiveMinimum: 0
                },
                serializedName: "calculationInnerTourLogistic",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationAccommodation: {
                constraints: {
                    InclusiveMinimum: 0
                },
                serializedName: "calculationAccommodation",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationMeal: {
                constraints: {
                    InclusiveMinimum: 0
                },
                serializedName: "calculationMeal",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationExcursion: {
                constraints: {
                    InclusiveMinimum: 0
                },
                serializedName: "calculationExcursion",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationProfitProgram: {
                constraints: {
                    InclusiveMinimum: 0
                },
                serializedName: "calculationProfitProgram",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            calculationInsurance: {
                constraints: {
                    InclusiveMinimum: 0
                },
                serializedName: "calculationInsurance",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            confirmationMailLink: {
                serializedName: "confirmationMailLink",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourOperatorComment: {
                serializedName: "tourOperatorComment",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const StringApiResponse = {
    type: {
        name: "Composite",
        className: "StringApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ProjectRsvApiDto = {
    type: {
        name: "Composite",
        className: "ProjectRsvApiDto",
        modelProperties: {
            projectId: {
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            projectName: {
                serializedName: "projectName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tableType: {
                serializedName: "tableType",
                readOnly: true,
                type: {
                    name: "Number"
                }
            },
            hash: {
                serializedName: "hash",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const CandidateMemberListRsvApiDto = {
    type: {
        name: "Composite",
        className: "CandidateMemberListRsvApiDto",
        modelProperties: {
            candidateMemberListId: {
                serializedName: "candidateMemberListId",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            projectId: {
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            isSpecialProject: {
                serializedName: "isSpecialProject",
                type: {
                    name: "Boolean"
                }
            },
            projectName: {
                serializedName: "projectName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const CandidateMemberRsvApiDto = {
    type: {
        name: "Composite",
        className: "CandidateMemberRsvApiDto",
        modelProperties: {
            candidateMemberId: {
                serializedName: "candidateMemberId",
                type: {
                    name: "Number"
                }
            },
            candidateMemberListId: {
                serializedName: "candidateMemberListId",
                type: {
                    name: "Number"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            birthDate: {
                serializedName: "birthDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            category: {
                serializedName: "category",
                type: {
                    name: "Number"
                }
            },
            phone: {
                serializedName: "phone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isSpecialProject: {
                serializedName: "isSpecialProject",
                type: {
                    name: "Boolean"
                }
            },
            projectId: {
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            projectName: {
                serializedName: "projectName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tableType: {
                serializedName: "tableType",
                readOnly: true,
                type: {
                    name: "Number"
                }
            },
            hash: {
                serializedName: "hash",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            candidateMemberStatus: {
                serializedName: "candidateMemberStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            candidateMemberCompetition: {
                serializedName: "candidateMemberCompetition",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const AcceptHashRecordsRsvApiDto = {
    type: {
        name: "Composite",
        className: "AcceptHashRecordsRsvApiDto",
        modelProperties: {
            tableType: {
                serializedName: "tableType",
                type: {
                    name: "Number"
                }
            },
            records: {
                serializedName: "records",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "AcceptHashRecordRsvApiDto"
                        }
                    }
                }
            }
        }
    }
};
export const AcceptHashRecordRsvApiDto = {
    type: {
        name: "Composite",
        className: "AcceptHashRecordRsvApiDto",
        modelProperties: {
            id: {
                serializedName: "id",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            hash: {
                serializedName: "hash",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const MemberRsvApiDto = {
    type: {
        name: "Composite",
        className: "MemberRsvApiDto",
        modelProperties: {
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            phone: {
                serializedName: "phone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tableType: {
                serializedName: "tableType",
                readOnly: true,
                type: {
                    name: "Number"
                }
            },
            hash: {
                serializedName: "hash",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const MemberRsvWithCandidatesRsvApiDto = {
    type: {
        name: "Composite",
        className: "MemberRsvWithCandidatesRsvApiDto",
        modelProperties: {
            candidates: {
                serializedName: "candidates",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CandidateMemberRsvApiDto"
                        }
                    }
                }
            },
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            phone: {
                serializedName: "phone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tableType: {
                serializedName: "tableType",
                readOnly: true,
                type: {
                    name: "Number"
                }
            },
            hash: {
                serializedName: "hash",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const CertificateRsvApiDto = {
    type: {
        name: "Composite",
        className: "CertificateRsvApiDto",
        modelProperties: {
            certificate: {
                serializedName: "certificate",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            amount: {
                serializedName: "amount",
                type: {
                    name: "Number"
                }
            },
            categoryKey: {
                serializedName: "categoryKey",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            category: {
                serializedName: "category",
                readOnly: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const PaymentRegistryRsvApiDto = {
    type: {
        name: "Composite",
        className: "PaymentRegistryRsvApiDto",
        modelProperties: {
            paymentRegistryId: {
                serializedName: "paymentRegistryId",
                type: {
                    name: "Uuid"
                }
            },
            rsvAccepted: {
                serializedName: "rsvAccepted",
                type: {
                    name: "Boolean"
                }
            },
            status: {
                serializedName: "status",
                type: {
                    name: "Number"
                }
            },
            statusName: {
                serializedName: "statusName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            uploadDateTime: {
                serializedName: "uploadDateTime",
                type: {
                    name: "DateTime"
                }
            },
            isDeleted: {
                serializedName: "isDeleted",
                type: {
                    name: "Boolean"
                }
            },
            paymentRegistryNumber: {
                serializedName: "paymentRegistryNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            paymentRegistryDate: {
                serializedName: "paymentRegistryDate",
                type: {
                    name: "DateTime"
                }
            },
            tableType: {
                serializedName: "tableType",
                readOnly: true,
                type: {
                    name: "Number"
                }
            },
            hash: {
                serializedName: "hash",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const PaymentRegistryWithItemsRsvApiDto = {
    type: {
        name: "Composite",
        className: "PaymentRegistryWithItemsRsvApiDto",
        modelProperties: {
            items: {
                serializedName: "items",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "PaymentRegistryItemRsvApiDto"
                        }
                    }
                }
            },
            paymentRegistryId: {
                serializedName: "paymentRegistryId",
                type: {
                    name: "Uuid"
                }
            },
            rsvAccepted: {
                serializedName: "rsvAccepted",
                type: {
                    name: "Boolean"
                }
            },
            status: {
                serializedName: "status",
                type: {
                    name: "Number"
                }
            },
            statusName: {
                serializedName: "statusName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            uploadDateTime: {
                serializedName: "uploadDateTime",
                type: {
                    name: "DateTime"
                }
            },
            isDeleted: {
                serializedName: "isDeleted",
                type: {
                    name: "Boolean"
                }
            },
            paymentRegistryNumber: {
                serializedName: "paymentRegistryNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            paymentRegistryDate: {
                serializedName: "paymentRegistryDate",
                type: {
                    name: "DateTime"
                }
            },
            tableType: {
                serializedName: "tableType",
                readOnly: true,
                type: {
                    name: "Number"
                }
            },
            hash: {
                serializedName: "hash",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const PaymentRegistryItemRsvApiDto = {
    type: {
        name: "Composite",
        className: "PaymentRegistryItemRsvApiDto",
        modelProperties: {
            positionId: {
                serializedName: "positionId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            fullName: {
                serializedName: "fullName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            certificate: {
                serializedName: "certificate",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            document: {
                serializedName: "document",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            candidateMemberId: {
                serializedName: "candidateMemberId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            tourOperatorNumber: {
                serializedName: "tourOperatorNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            administratorNumber: {
                serializedName: "administratorNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            price: {
                serializedName: "price",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            candidateMemberCompetition: {
                serializedName: "candidateMemberCompetition",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            candidateMemberStatus: {
                serializedName: "candidateMemberStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourDateBegin: {
                serializedName: "tourDateBegin",
                type: {
                    name: "DateTime"
                }
            },
            tourDateEnd: {
                serializedName: "tourDateEnd",
                type: {
                    name: "DateTime"
                }
            },
            townFrom: {
                serializedName: "townFrom",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            townTo: {
                serializedName: "townTo",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const RsvResultDtoPaginateApiResponse = {
    type: {
        name: "Composite",
        className: "RsvResultDtoPaginateApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "RsvResultDtoPaginate"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const RsvResultDtoPaginate = {
    type: {
        name: "Composite",
        className: "RsvResultDtoPaginate",
        modelProperties: {
            data: {
                serializedName: "data",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RsvResultDto"
                        }
                    }
                }
            },
            pageSize: {
                serializedName: "pageSize",
                type: {
                    name: "Number"
                }
            },
            page: {
                serializedName: "page",
                type: {
                    name: "Number"
                }
            },
            count: {
                serializedName: "count",
                type: {
                    name: "Number"
                }
            },
            optionalSummaries: {
                serializedName: "optionalSummaries",
                nullable: true,
                type: {
                    name: "Dictionary",
                    value: { type: { name: "String" } }
                }
            }
        }
    }
};
export const RsvResultDto = {
    type: {
        name: "Composite",
        className: "RsvResultDto",
        modelProperties: {
            rsvResultId: {
                serializedName: "rsvResultId",
                type: {
                    name: "Number"
                }
            },
            number: {
                serializedName: "number",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            groupNumber: {
                serializedName: "groupNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourDate: {
                serializedName: "tourDate",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourName: {
                serializedName: "tourName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            project: {
                serializedName: "project",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            touristsCount: {
                serializedName: "touristsCount",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            certificateAmount: {
                serializedName: "certificateAmount",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            providerPayment: {
                serializedName: "providerPayment",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            providerMoneyback: {
                serializedName: "providerMoneyback",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            registryNumber: {
                serializedName: "registryNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            mtt: {
                serializedName: "mtt",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            checkStatus: {
                serializedName: "checkStatus",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            comments: {
                serializedName: "comments",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fpr: {
                serializedName: "fpr",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            paymentDates: {
                serializedName: "paymentDates",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            numberPP: {
                serializedName: "numberPP",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            numberAKK: {
                serializedName: "numberAKK",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            documentsSentForAgreementDate: {
                serializedName: "documentsSentForAgreementDate",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            documentsSentForDSRecieveDate: {
                serializedName: "documentsSentForDSRecieveDate",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            dsRecievedFromRsv: {
                serializedName: "dsRecievedFromRsv",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            dsRecievedFromRsvDate: {
                serializedName: "dsRecievedFromRsvDate",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            avAmount: {
                serializedName: "avAmount",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            avReceivedAmount: {
                serializedName: "avReceivedAmount",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            avReceivedDate: {
                serializedName: "avReceivedDate",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            ticket: {
                serializedName: "ticket",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            creditDisclosureDate: {
                serializedName: "creditDisclosureDate",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            notes: {
                serializedName: "notes",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ToursSearchVmApiResponse = {
    type: {
        name: "Composite",
        className: "ToursSearchVmApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ToursSearchVm"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ToursSearchVm = {
    type: {
        name: "Composite",
        className: "ToursSearchVm",
        modelProperties: {
            tours: {
                serializedName: "tours",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "SearchTourDto"
                        }
                    }
                }
            },
            toursSearchResultCount: {
                serializedName: "toursSearchResultCount",
                type: {
                    name: "Number"
                }
            },
            tourGroups: {
                serializedName: "tourGroups",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "TourGroupDto"
                        }
                    }
                }
            },
            tourGroupsSearchResultCount: {
                serializedName: "tourGroupsSearchResultCount",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const SearchTourDto = {
    type: {
        name: "Composite",
        className: "SearchTourDto",
        modelProperties: {
            externalTourId: {
                serializedName: "externalTourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            title: {
                serializedName: "title",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourType: {
                serializedName: "tourType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isKidsTour: {
                serializedName: "isKidsTour",
                nullable: true,
                type: {
                    name: "Boolean"
                }
            },
            nights: {
                serializedName: "nights",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            statusId: {
                serializedName: "statusId",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const MemberSearchVmApiResponse = {
    type: {
        name: "Composite",
        className: "MemberSearchVmApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "MemberSearchVm"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const MemberSearchVm = {
    type: {
        name: "Composite",
        className: "MemberSearchVm",
        modelProperties: {
            members: {
                serializedName: "members",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MemberSearchDto"
                        }
                    }
                }
            },
            membersSearchResultCount: {
                serializedName: "membersSearchResultCount",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const MemberSearchDto = {
    type: {
        name: "Composite",
        className: "MemberSearchDto",
        modelProperties: {
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            phone: {
                serializedName: "phone",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fullName: {
                serializedName: "fullName",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            },
            birthDateFormatted: {
                serializedName: "birthDateFormatted",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            },
            birthDate: {
                serializedName: "birthDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export const ClaimSearchVmApiResponse = {
    type: {
        name: "Composite",
        className: "ClaimSearchVmApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ClaimSearchVm"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimSearchVm = {
    type: {
        name: "Composite",
        className: "ClaimSearchVm",
        modelProperties: {
            claims: {
                serializedName: "claims",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ClaimSearchDto"
                        }
                    }
                }
            },
            claimsSearchResultCount: {
                serializedName: "claimsSearchResultCount",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimSearchDto = {
    type: {
        name: "Composite",
        className: "ClaimSearchDto",
        modelProperties: {
            bookingCenterClaimId: {
                serializedName: "bookingCenterClaimId",
                type: {
                    name: "Uuid"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const MemberAccessSletatDto = {
    type: {
        name: "Composite",
        className: "MemberAccessSletatDto",
        modelProperties: {
            memberAccessId: {
                serializedName: "memberAccessId",
                type: {
                    name: "Number"
                }
            },
            memberId: {
                serializedName: "memberId",
                type: {
                    name: "Number"
                }
            },
            accessId: {
                serializedName: "accessId",
                type: {
                    name: "Uuid"
                }
            },
            programName: {
                serializedName: "programName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createTimeUtc: {
                serializedName: "createTimeUtc",
                type: {
                    name: "DateTime"
                }
            },
            expireTimeUtc: {
                serializedName: "expireTimeUtc",
                type: {
                    name: "DateTime"
                }
            },
            status: {
                serializedName: "status",
                type: {
                    name: "Number"
                }
            },
            tourCategory: {
                serializedName: "tourCategory",
                type: {
                    name: "Number"
                }
            },
            tourType: {
                serializedName: "tourType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            accessTourIds: {
                serializedName: "accessTourIds",
                nullable: true,
                type: {
                    name: "any"
                }
            },
            accessGroupIds: {
                serializedName: "accessGroupIds",
                nullable: true,
                type: {
                    name: "any"
                }
            },
            allowSelfExtend: {
                serializedName: "allowSelfExtend",
                type: {
                    name: "Boolean"
                }
            },
            birthDate: {
                serializedName: "birthDate",
                type: {
                    name: "DateTime"
                }
            },
            sletatUserId: {
                serializedName: "sletatUserId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            competition: {
                serializedName: "competition",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ClaimCompetitionDto = {
    type: {
        name: "Composite",
        className: "ClaimCompetitionDto",
        modelProperties: {
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            competition: {
                serializedName: "competition",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const SuccessHistoryCreateDto = {
    type: {
        name: "Composite",
        className: "SuccessHistoryCreateDto",
        modelProperties: {
            sletatUserId: {
                serializedName: "sletatUserId",
                type: {
                    name: "Uuid"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lifeChangedDescription: {
                serializedName: "lifeChangedDescription",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tglink: {
                serializedName: "tglink",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            vklink: {
                serializedName: "vklink",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            anySocialNetworkLink: {
                serializedName: "anySocialNetworkLink",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            story: {
                serializedName: "story",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const SuccessHistoryDtoPaginateApiResponse = {
    type: {
        name: "Composite",
        className: "SuccessHistoryDtoPaginateApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "SuccessHistoryDtoPaginate"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const SuccessHistoryDtoPaginate = {
    type: {
        name: "Composite",
        className: "SuccessHistoryDtoPaginate",
        modelProperties: {
            data: {
                serializedName: "data",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "SuccessHistoryDto"
                        }
                    }
                }
            },
            pageSize: {
                serializedName: "pageSize",
                type: {
                    name: "Number"
                }
            },
            page: {
                serializedName: "page",
                type: {
                    name: "Number"
                }
            },
            count: {
                serializedName: "count",
                type: {
                    name: "Number"
                }
            },
            optionalSummaries: {
                serializedName: "optionalSummaries",
                nullable: true,
                type: {
                    name: "Dictionary",
                    value: { type: { name: "String" } }
                }
            }
        }
    }
};
export const SuccessHistoryDto = {
    type: {
        name: "Composite",
        className: "SuccessHistoryDto",
        modelProperties: {
            successHistoryId: {
                serializedName: "successHistoryId",
                type: {
                    name: "Number"
                }
            },
            touristFullName: {
                serializedName: "touristFullName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            touristEmail: {
                serializedName: "touristEmail",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            touristAge: {
                serializedName: "touristAge",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            region: {
                serializedName: "region",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            touristCity: {
                serializedName: "touristCity",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            dateBegin: {
                serializedName: "dateBegin",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            dateEnd: {
                serializedName: "dateEnd",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            story: {
                serializedName: "story",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lifeChangedDescription: {
                serializedName: "lifeChangedDescription",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tglink: {
                serializedName: "tglink",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            vklink: {
                serializedName: "vklink",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            anySocialNetworkLink: {
                serializedName: "anySocialNetworkLink",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const SyncBudgetDtoListApiResponse = {
    type: {
        name: "Composite",
        className: "SyncBudgetDtoListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "SyncBudgetDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const SyncBudgetDto = {
    type: {
        name: "Composite",
        className: "SyncBudgetDto",
        modelProperties: {
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            budgetId: {
                serializedName: "budgetId",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const SyncGenderDtoListApiResponse = {
    type: {
        name: "Composite",
        className: "SyncGenderDtoListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "SyncGenderDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const SyncGenderDto = {
    type: {
        name: "Composite",
        className: "SyncGenderDto",
        modelProperties: {
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            gender: {
                serializedName: "gender",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const SyncProviderDtoListApiResponse = {
    type: {
        name: "Composite",
        className: "SyncProviderDtoListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "SyncProviderDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const SyncProviderDto = {
    type: {
        name: "Composite",
        className: "SyncProviderDto",
        modelProperties: {
            sletatId: {
                serializedName: "sletatId",
                type: {
                    name: "Number"
                }
            },
            providerName: {
                serializedName: "providerName",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const SyncTourRegionDtoListApiResponse = {
    type: {
        name: "Composite",
        className: "SyncTourRegionDtoListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "SyncTourRegionDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const SyncTourRegionDto = {
    type: {
        name: "Composite",
        className: "SyncTourRegionDto",
        modelProperties: {
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            region: {
                serializedName: "region",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const TouristChatInfoApiModelApiResponse = {
    type: {
        name: "Composite",
        className: "TouristChatInfoApiModelApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "TouristChatInfoApiModel"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const TouristChatInfoApiModel = {
    type: {
        name: "Composite",
        className: "TouristChatInfoApiModel",
        modelProperties: {
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            chats: {
                serializedName: "chats",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChatInfoApiModel"
                        }
                    }
                }
            }
        }
    }
};
export const ChatInfoApiModel = {
    type: {
        name: "Composite",
        className: "ChatInfoApiModel",
        modelProperties: {
            tourName: {
                serializedName: "tourName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            townFrom: {
                serializedName: "townFrom",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            begin: {
                serializedName: "begin",
                type: {
                    name: "DateTime"
                }
            },
            end: {
                serializedName: "end",
                type: {
                    name: "DateTime"
                }
            },
            tripType: {
                serializedName: "tripType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            linkToChat: {
                serializedName: "linkToChat",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const TourGroupDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "TourGroupDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "TourGroupDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const TourGroupDtoPaginateApiResponse = {
    type: {
        name: "Composite",
        className: "TourGroupDtoPaginateApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "TourGroupDtoPaginate"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const TourGroupDtoPaginate = {
    type: {
        name: "Composite",
        className: "TourGroupDtoPaginate",
        modelProperties: {
            data: {
                serializedName: "data",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "TourGroupDto"
                        }
                    }
                }
            },
            pageSize: {
                serializedName: "pageSize",
                type: {
                    name: "Number"
                }
            },
            page: {
                serializedName: "page",
                type: {
                    name: "Number"
                }
            },
            count: {
                serializedName: "count",
                type: {
                    name: "Number"
                }
            },
            optionalSummaries: {
                serializedName: "optionalSummaries",
                nullable: true,
                type: {
                    name: "Dictionary",
                    value: { type: { name: "String" } }
                }
            }
        }
    }
};
export const TourGroupOccupationDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "TourGroupOccupationDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "TourGroupOccupationDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const TourGroupOccupationDto = {
    type: {
        name: "Composite",
        className: "TourGroupOccupationDto",
        modelProperties: {
            tourId: {
                serializedName: "tourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            groupId: {
                serializedName: "groupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            filled: {
                serializedName: "filled",
                type: {
                    name: "Number"
                }
            },
            maxCapacity: {
                serializedName: "maxCapacity",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const TelegramLinkUpdateDto = {
    type: {
        name: "Composite",
        className: "TelegramLinkUpdateDto",
        modelProperties: {
            tourGroupId: {
                serializedName: "tourGroupId",
                type: {
                    name: "Uuid"
                }
            },
            telegramLink: {
                serializedName: "telegramLink",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const TourGroupFlatDtoApiResponse = {
    type: {
        name: "Composite",
        className: "TourGroupFlatDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "TourGroupFlatDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const TourGroupFlatDto = {
    type: {
        name: "Composite",
        className: "TourGroupFlatDto",
        modelProperties: {
            tourGroupId: {
                serializedName: "tourGroupId",
                type: {
                    name: "Uuid"
                }
            },
            externalTourGroupId: {
                serializedName: "externalTourGroupId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourId: {
                serializedName: "tourId",
                type: {
                    name: "Uuid"
                }
            },
            externalTourId: {
                serializedName: "externalTourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourCost: {
                serializedName: "tourCost",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            start: {
                serializedName: "start",
                type: {
                    name: "DateTime"
                }
            },
            end: {
                serializedName: "end",
                type: {
                    name: "DateTime"
                }
            },
            budgetId: {
                serializedName: "budgetId",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const TourDtoApiResponse = {
    type: {
        name: "Composite",
        className: "TourDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "TourDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const TourDto = {
    type: {
        name: "Composite",
        className: "TourDto",
        modelProperties: {
            externalTourId: {
                serializedName: "externalTourId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            title: {
                serializedName: "title",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourType: {
                serializedName: "tourType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isKidsTour: {
                serializedName: "isKidsTour",
                nullable: true,
                type: {
                    name: "Boolean"
                }
            },
            days: {
                serializedName: "days",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            nights: {
                serializedName: "nights",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            statusId: {
                serializedName: "statusId",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            tourGroups: {
                serializedName: "tourGroups",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "TourGroupDto"
                        }
                    }
                }
            }
        }
    }
};
export const TourDtoListApiResponse = {
    type: {
        name: "Composite",
        className: "TourDtoListApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "TourDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const UserCreateRequest = {
    type: {
        name: "Composite",
        className: "UserCreateRequest",
        modelProperties: {
            setPasswordAfterConfirmation: {
                serializedName: "setPasswordAfterConfirmation",
                type: {
                    name: "Boolean"
                }
            },
            password: {
                serializedName: "password",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isActive: {
                serializedName: "isActive",
                type: {
                    name: "Boolean"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            roles: {
                serializedName: "roles",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            }
        }
    }
};
export const UserDTOApiResponse = {
    type: {
        name: "Composite",
        className: "UserDTOApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "UserDTO"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const UserPasswordResetRequest = {
    type: {
        name: "Composite",
        className: "UserPasswordResetRequest",
        modelProperties: {
            login: {
                serializedName: "login",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            token: {
                serializedName: "token",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            newPassword: {
                serializedName: "newPassword",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const UserConfirmRequest = {
    type: {
        name: "Composite",
        className: "UserConfirmRequest",
        modelProperties: {
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            token: {
                serializedName: "token",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            password: {
                serializedName: "password",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const UserUpdateRequest = {
    type: {
        name: "Composite",
        className: "UserUpdateRequest",
        modelProperties: {
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            login: {
                serializedName: "login",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            roles: {
                serializedName: "roles",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            }
        }
    }
};
export const UserPaginatedResultApiResponse = {
    type: {
        name: "Composite",
        className: "UserPaginatedResultApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "UserPaginatedResult"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const UserPaginatedResult = {
    type: {
        name: "Composite",
        className: "UserPaginatedResult",
        modelProperties: {
            skipped: {
                serializedName: "skipped",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            taked: {
                serializedName: "taked",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            totalCount: {
                serializedName: "totalCount",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "User"
                        }
                    }
                }
            }
        }
    }
};
export const User = {
    type: {
        name: "Composite",
        className: "User",
        modelProperties: {
            aspNetUserId: {
                serializedName: "aspNetUserId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            isActive: {
                serializedName: "isActive",
                nullable: true,
                type: {
                    name: "Boolean"
                }
            },
            login: {
                serializedName: "login",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            roles: {
                serializedName: "roles",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            }
        }
    }
};
export const ProblemDetails = {
    type: {
        name: "Composite",
        className: "ProblemDetails",
        additionalProperties: { type: { name: "Object" } },
        modelProperties: {
            type: {
                serializedName: "type",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            title: {
                serializedName: "title",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            status: {
                serializedName: "status",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            detail: {
                serializedName: "detail",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            instance: {
                serializedName: "instance",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const UserToBudgetAccessDto = {
    type: {
        name: "Composite",
        className: "UserToBudgetAccessDto",
        modelProperties: {
            userToBudgetAccessId: {
                serializedName: "userToBudgetAccessId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            aspNetUserId: {
                constraints: {
                    MinLength: 1
                },
                serializedName: "aspNetUserId",
                required: true,
                type: {
                    name: "String"
                }
            },
            budgetId: {
                serializedName: "budgetId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const UserToBudgetAccessDtoApiResponse = {
    type: {
        name: "Composite",
        className: "UserToBudgetAccessDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "UserToBudgetAccessDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const BadRequestObjectResult = {
    type: {
        name: "Composite",
        className: "BadRequestObjectResult",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const BadRequestResult = {
    type: {
        name: "Composite",
        className: "BadRequestResult",
        modelProperties: {
            statusCode: {
                serializedName: "statusCode",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const FileStreamResultApiResponse = {
    type: {
        name: "Composite",
        className: "FileStreamResultApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Stream"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const NotFoundObjectResult = {
    type: {
        name: "Composite",
        className: "NotFoundObjectResult",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const NotFoundResult = {
    type: {
        name: "Composite",
        className: "NotFoundResult",
        modelProperties: {
            statusCode: {
                serializedName: "statusCode",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ObjectResult = {
    type: {
        name: "Composite",
        className: "ObjectResult",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const Paths10Yk07IApiCandidatememberlistfilesListidPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths10Yk07IApiCandidatememberlistfilesListidPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            files: {
                serializedName: "files",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            }
        }
    }
};
export const Paths1SpuipsApiCandidatememberlistsParsePostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths1SpuipsApiCandidatememberlistsParsePostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            file: {
                serializedName: "file",
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const PathsW7BkzeApiCertificatepaymentregistriesAcceptcertificatepaymentregistryPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "PathsW7BkzeApiCertificatepaymentregistriesAcceptcertificatepaymentregistryPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            certificatePaymentRegistryId: {
                serializedName: "certificatePaymentRegistryId",
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const PathsXyipbwApiCertificatepaymentregistriesSetstatuscertificatepaymentregistryPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "PathsXyipbwApiCertificatepaymentregistriesSetstatuscertificatepaymentregistryPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            certificatePaymentRegistryId: {
                serializedName: "certificatePaymentRegistryId",
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const PathsPkcbclApiCertificatepaymentregistriesDeletecertificatepaymentregistryPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "PathsPkcbclApiCertificatepaymentregistriesDeletecertificatepaymentregistryPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            certificatePaymentRegistryId: {
                serializedName: "certificatePaymentRegistryId",
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const Paths2NkruhApiCertificatepaymentregistriesParseregistryexcelPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths2NkruhApiCertificatepaymentregistriesParseregistryexcelPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            file: {
                serializedName: "file",
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const Paths1Uob7C4ApiCertificatepaymentregistriesNewregistryPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths1Uob7C4ApiCertificatepaymentregistriesNewregistryPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            file: {
                serializedName: "File",
                type: {
                    name: "Stream"
                }
            },
            budgetId: {
                serializedName: "BudgetId",
                type: {
                    name: "Number"
                }
            },
            registryType: {
                serializedName: "RegistryType",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const Paths1G6YklvApiClaimdocumentsClaimidClaimDocumentsUploadPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths1G6YklvApiClaimdocumentsClaimidClaimDocumentsUploadPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            formFiles: {
                serializedName: "formFiles",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            changeStatus: {
                serializedName: "changeStatus",
                type: {
                    name: "Boolean"
                }
            },
            newStatus: {
                serializedName: "newStatus",
                type: {
                    name: "Number"
                }
            },
            fileType: {
                serializedName: "fileType",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const PathsP0I39YApiClaimdocumentsClaimResultDocumentsUploadClaimidPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "PathsP0I39YApiClaimdocumentsClaimResultDocumentsUploadClaimidPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            tickets: {
                serializedName: "tickets",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            vouchers: {
                serializedName: "vouchers",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            insurance: {
                serializedName: "insurance",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            accomodation: {
                serializedName: "accomodation",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            absent: {
                serializedName: "absent",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            explanatory: {
                serializedName: "explanatory",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            compress: {
                serializedName: "compress",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const Paths1Q9Us6DApiClaimdocumentsClaimResultAdmDocumentsUploadClaimidPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths1Q9Us6DApiClaimdocumentsClaimResultAdmDocumentsUploadClaimidPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            agencyReports: {
                serializedName: "agencyReports",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            claims: {
                serializedName: "claims",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            acts: {
                serializedName: "acts",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            registries: {
                serializedName: "registries",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            bookingLists: {
                serializedName: "bookingLists",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            paymentOrders: {
                serializedName: "paymentOrders",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            compress: {
                serializedName: "compress",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const Paths1Vc90BkApiClaimdocumentsClaimidResultClaimDocumentDirectUploadPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths1Vc90BkApiClaimdocumentsClaimidResultClaimDocumentDirectUploadPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            contentType: {
                serializedName: "ContentType",
                type: {
                    name: "String"
                }
            },
            contentDisposition: {
                serializedName: "ContentDisposition",
                type: {
                    name: "String"
                }
            },
            headers: {
                serializedName: "Headers",
                type: {
                    name: "Dictionary",
                    value: {
                        type: { name: "Sequence", element: { type: { name: "String" } } }
                    }
                }
            },
            length: {
                serializedName: "Length",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "Name",
                type: {
                    name: "String"
                }
            },
            fileName: {
                serializedName: "FileName",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const Paths136FmdpApiClaimdocumentsGroupClaimsResultDocumentsUploadGroupnumberPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths136FmdpApiClaimdocumentsGroupClaimsResultDocumentsUploadGroupnumberPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            tickets: {
                serializedName: "tickets",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            vouchers: {
                serializedName: "vouchers",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            insurance: {
                serializedName: "insurance",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            accomodation: {
                serializedName: "accomodation",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            absent: {
                serializedName: "absent",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            explanatory: {
                serializedName: "explanatory",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            compress: {
                serializedName: "compress",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const Paths1Gq0ZwpGroupClaimDocumentsUploadGroupidPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths1Gq0ZwpGroupClaimDocumentsUploadGroupidPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            formFiles: {
                serializedName: "formFiles",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Stream"
                        }
                    }
                }
            },
            changeStatus: {
                serializedName: "changeStatus",
                type: {
                    name: "Boolean"
                }
            },
            newStatus: {
                serializedName: "newStatus",
                type: {
                    name: "Number"
                }
            },
            fileType: {
                serializedName: "fileType",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const Paths8Fv01XApiConversationsUploadMesageFilePostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths8Fv01XApiConversationsUploadMesageFilePostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            file: {
                serializedName: "file",
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const Paths1Kxoms3ApiFilesPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths1Kxoms3ApiFilesPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            traceId: {
                serializedName: "TraceId",
                type: {
                    name: "Uuid"
                }
            },
            file: {
                serializedName: "File",
                required: true,
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const Paths1Hf4Vd2ApiMembersEditRequestRequestidAttachFilePostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths1Hf4Vd2ApiMembersEditRequestRequestidAttachFilePostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            file: {
                serializedName: "file",
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const PathsEw516MApiPaperInformationNoticeDocumentUploadThumbnailWidthHeightPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "PathsEw516MApiPaperInformationNoticeDocumentUploadThumbnailWidthHeightPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            file: {
                serializedName: "file",
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const PathsGerskhApiPaperInformationNoticeRequestidSendArtifactPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "PathsGerskhApiPaperInformationNoticeRequestidSendArtifactPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            file: {
                serializedName: "file",
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
export const Paths1M57TiyApiResultclaimsSetresultclaimtouroperatorstatusPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths1M57TiyApiResultclaimsSetresultclaimtouroperatorstatusPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            claimId: {
                serializedName: "claimId",
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const Paths1VvjogfApiResultclaimsSetresultclaimadministratorstatusPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths1VvjogfApiResultclaimsSetresultclaimadministratorstatusPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            claimId: {
                serializedName: "claimId",
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const Paths1M6ArktApiResultclaimsSetgroupresultclaimtouroperatorstatusPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths1M6ArktApiResultclaimsSetgroupresultclaimtouroperatorstatusPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            group: {
                serializedName: "group",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const Paths1I88UaApiResultclaimsSetgroupresultclaimadministratorstatusPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths1I88UaApiResultclaimsSetgroupresultclaimadministratorstatusPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            group: {
                serializedName: "group",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const Paths1124ZApiResultclaimsSetgroupresultclaimresultcheckstatusPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths1124ZApiResultclaimsSetgroupresultclaimresultcheckstatusPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            group: {
                serializedName: "group",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const Paths165G5H0ApiResultgroupsUploadConfirmationMailPostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths165G5H0ApiResultgroupsUploadConfirmationMailPostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            file: {
                serializedName: "file",
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
