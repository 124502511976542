import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { Layout, notification, } from "antd";
import { useDispatch, useSelector } from "react-redux";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";
import Topbar from "../Topbar/index";
import App from "../../routes";
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { NAV_STYLE_ABOVE_HEADER, NAV_STYLE_BELOW_HEADER, NAV_STYLE_DARK_HORIZONTAL, NAV_STYLE_DEFAULT_HORIZONTAL, NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_INSIDE_HEADER_HORIZONTAL, NAV_STYLE_MINI_SIDEBAR, NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR, NAV_STYLE_NO_HEADER_MINI_SIDEBAR } from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { useRouteMatch } from "react-router-dom";
import { updateWindowWidth } from "../../appRedux/actions";
import AppSidebar from "./AppSidebar";
const { Content, Footer } = Layout;
const getContainerClass = (navStyle) => {
    switch (navStyle) {
        case NAV_STYLE_DARK_HORIZONTAL:
            return "gx-container-wrap";
        case NAV_STYLE_DEFAULT_HORIZONTAL:
            return "gx-container-wrap";
        case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
            return "gx-container-wrap";
        case NAV_STYLE_BELOW_HEADER:
            return "gx-container-wrap";
        case NAV_STYLE_ABOVE_HEADER:
            return "gx-container-wrap";
        default:
            return '';
    }
};
const getNavStyles = (navStyle, auth) => {
    switch (navStyle) {
        case NAV_STYLE_DEFAULT_HORIZONTAL:
            return _jsx(HorizontalDefault, {});
        case NAV_STYLE_DARK_HORIZONTAL:
            return _jsx(HorizontalDark, {});
        case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
            return _jsx(InsideHeader, {});
        case NAV_STYLE_ABOVE_HEADER:
            return _jsx(AboveHeader, {});
        case NAV_STYLE_BELOW_HEADER:
            return _jsx(BelowHeader, {});
        case NAV_STYLE_FIXED:
            return _jsx(Topbar, {});
        case NAV_STYLE_DRAWER:
            return _jsx(Topbar, {});
        case NAV_STYLE_MINI_SIDEBAR:
            return _jsx(Topbar, {});
        case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
            return _jsx(NoHeaderNotification, {});
        case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
            return _jsx(NoHeaderNotification, {});
        default:
            return null;
    }
};
const MainApp = () => {
    const { auth, navStyle } = useSelector((e) => e.settings);
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [notificationApi, notificationHolder] = notification.useNotification();
    useEffect(() => {
        document.title = 'Администирование БЧП';
        window.addEventListener('resize', () => {
            dispatch(updateWindowWidth(window.innerWidth));
        });
    }, [dispatch]);
    return (_jsxs(_Fragment, { children: [notificationHolder, _jsxs(Layout, Object.assign({ className: "gx-app-layout" }, { children: [_jsx(AppSidebar, { navStyle: navStyle, auth: auth }), _jsx(Layout, { children: _jsx(Content, Object.assign({ className: `gx-layout-content ${getContainerClass(navStyle)} ` }, { children: _jsx(App, { match: match }) })) })] }))] }));
};
export default MainApp;
